// analysis.js
import $ from 'jquery';
import { Layers, getActiveLayer } from '../main';
import { region_selected, region_selected_single, admin_selected } from './selectRegion';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { map, view, RasterLayer } from './layers';
//chart.js
import { Chart, registerables } from 'chart.js';
// chart.js boxplots
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';

// data labels
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.register(ChartDataLabels);

//var api_url = "http://landsurf.geo.uni-halle.de:8000";
var api_url = "https://landsurf.geo.uni-halle.de";



function generateID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


function createCanvas() {
    // Create the canvas element
    var canvas = document.createElement('canvas');
    canvas.id = "chartCanvas_" + generateID(5);
    canvas.className = "chart-canvas";

    var canvasDiv = document.createElement('div');
    canvasDiv.className = 'chart-and-button-container';

    // Add the canvas to your container div
    var chartContainer = document.getElementById('panelChartWrapper');
    chartContainer.appendChild(canvasDiv);
    canvasDiv.appendChild(canvas);


    ////console.log(canvas);
    // Get the context of the newly created canvas
    return canvas;
}

function createCanvasPopup() {

    try {
        // destroy old canvas
        $('.chart-canvas-popup').remove();
    } catch (error) {

    }


    // Create the canvas element
    var canvas = document.createElement('canvas');
    canvas.id = "chartCanvas_" + generateID(5);
    canvas.className = "chart-canvas-popup";

    var canvasDiv = document.createElement('div');
    canvasDiv.className = 'chart-and-button-container-popup';

    // Add the canvas to your container div
    var chartContainer = document.getElementById('panelChartWrapperPopup');
    chartContainer.appendChild(canvasDiv);
    canvasDiv.appendChild(canvas);


    ////console.log(canvas);
    // Get the context of the newly created canvas
    return canvas;
}


function createAndPositionButton(context, buttonId, iconClass, type) {

    //console.log("creating buttons: " + buttonId + " ; " + iconClass);


    $('#destroyChartButtonPopup, #downloadChartButtonPopup').remove();

    // try {
    //     //$('.chart-button-popup').remove()
    //     // $('.chart-button').remove()
    // } catch (error) {
    // }


    // Create button element 
    const button = document.createElement('BUTTON');
    button.id = buttonId;

    switch (type) {
        case 'panel':
            button.classList.add('chart-button');
            break;
        case 'popup':
            button.classList.add('chart-button-popup');
            break;
    }

    if (buttonId == 'destroyChartButton' || buttonId == 'destroyChartButtonPopup') {
        button.classList.add('destroy-chart-button');
    }


    button.style.position = 'relative';

    // Add icon
    const icon = document.createElement('i');
    icon.id = buttonId + "Icon";
    // Split the icon class and add them individually:
    const iconClasses = iconClass.split(" "); // Split by spaces
    iconClasses.forEach(className => icon.classList.add(className));
    //icon.classList.add(iconClass);
    button.appendChild(icon);

    // Attach button as child to chart canvas wrapper  
    const chartWrapper = context.canvas.parentNode;
    chartWrapper.appendChild(button);

    //console.log(button);
    // //console.log(icon);
    // //console.log(chartWrapper);


    switch (type) {
        case 'panel':
            // // Adjust positions for different buttons as needed 
            if (buttonId === 'destroyChartButton') {
                button.style.bottom = '345px';
            } else if (buttonId === 'downloadChartButton') {
                button.style.bottom = '368px';
                button.style.right = '32px';
            }
            break;

        case 'popup':

            break;
    }


}


function createBarChartConf(type = 'panel', dataType = 'return', catchment = '', projType = 'none') {
    //console.log("CREATING BAR CHART CONF")
    //console.log(projType);

    var digits;
    var textX;
    var textY;

    if (dataType == 'synop' || dataType == 'return') {
        digits = 0;
        textX = 'return period';

        if (projType == 'diff') {
            textY = 'max prec. [%]';
        } else {
            textY = 'max prec. [mm]';
        }

    } else if (dataType == 'hazard') {
        digits = 2;

        textY = 'max vel. [m/s]';

        if (catchment == 'oueme') {
            textX = 'return period';
        } else if (catchment == 'kumasi') {
            textX = 'event duration';
        }

    }





    let barChartConf = {
        type: 'bar',
        plugins: [ChartDataLabels],
        data: '',
        options: {
            plugins: {
                title: {
                    display: true,
                    text: "",
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true
                },
                datalabels: {
                    anchor: 'center', // Position label in the center of the bar
                    align: 'center',
                    color: '#E1E1E1',   // Label color
                    font: {
                        weight: 'bold' // Label font style
                    },
                    formatter: function (value) {
                        return value.toFixed(digits);     // Display the data value as the label
                    }
                },
            },
            responsive: true,
            elements: {
                point: {
                    hitRadius: 10,
                    hoverRadius: 10,
                    borderWidth: 0,
                    radius: 0
                }
            },
            // callback function on resize
            onResize: (context) => {
                if (type == 'panel') {
                    //console.log("creating panel buttons");
                    createAndPositionButton(context, 'destroyChartButton', 'fa-solid fa-xmark', 'panel');
                    createAndPositionButton(context, 'downloadChartButton', 'fa-solid fa-download', 'panel');
                } else if (type == 'popup') {
                    //console.log("creating popup buttons");
                    createAndPositionButton(context, 'destroyChartButtonPopup', 'fa-solid fa-xmark', 'popup');
                    createAndPositionButton(context, 'downloadChartButtonPopup', 'fa-solid fa-download', 'popup');
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: textX
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: textY
                    }
                }
            }
        }
    }

    return barChartConf;
}


function createBoxPlotConf(type = 'panel') {

    let boxPlotConf = {
        type: 'boxplot',
        data: '',
        options: {
            plugins: {
                title: {
                    display: true,
                    text: "",
                },
                legend: {
                    display: true,
                },
                tooltip: {
                    enabled: true
                }
            },
            responsive: true,
            elements: {
                point: {
                    hitRadius: 10,
                    hoverRadius: 10,
                    borderWidth: 0,
                    radius: 0
                }
            },
            // callback function on resize
            onResize: (context) => {
                // get buttons
                if (type == 'panel') {
                    createAndPositionButton(context, 'destroyChartButton', 'fa-solid fa-xmark', 'panel');
                    createAndPositionButton(context, 'downloadChartButton', 'fa-solid fa-download', 'panel');
                } else if (type == 'popup') {
                    createAndPositionButton(context, 'destroyChartButtonPopup', 'fa-solid fa-xmark', 'popup');
                    createAndPositionButton(context, 'downloadChartButtonPopup', 'fa-solid fa-download', 'popup');
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'return period'
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'max prec. [mm]'
                    }
                }
            }
        }
    }

    return boxPlotConf;
}



//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////              ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    POPUP     ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////              //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//

// set class of popup button active / passive to change color and activate popup
$("#popupButton").on("click", function () {

    if ($('#selectRegionButton').attr("class").includes('active')) {
        //console.log("SELECT REGION ACTVE")
        alert("Stop selecting a region before creating a popup by clicking on the \"Select Region\" Button");
    } else {
        //console.log("SELECT REGION PASSIVE")
        if ($(this).attr("class").includes("passive")) {
            $(this).removeClass("passive");
            $(this).addClass("active");
        }
        else if ($(this).attr("class").includes("active")) {
            $(this).removeClass("active");
            $(this).addClass("passive");
        }
    }
})


// Elements that make up the popup.
const container = document.getElementById("popup");
const content = document.getElementById("popup-content");
const closer = document.getElementById("popup-closer");

// Create an overlay to anchor the popup to the map.
const overlay = new Overlay({
    element: container,
});

// Add a click handler to hide the popup.
// @return {boolean} Don't follow the href.
closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};




var info_btn = document.getElementById("popupButton");
// on map click event
// if info button passive, do nothing only render popup when active
// get coordinates and write them to popup div
// then show popup at coordinate position

map.on("click", function (evt) {
    ////console.log(info_btn.classList);
    if (info_btn.classList.contains("active")) {

        //console.log("POUPUP CLICK");

        var layerType = "none";


        // get currently active layer
        var activeLayer = getActiveLayer(Layers);


        // test if chart already exists and destroy if so, so new chart can be rendered instead
        if (typeof chart_popup !== 'undefined') {
            chart_popup.destroy();
            $('.chart-button-popup').remove();
        }


        // get coordinates and coordinates string
        const coordinate = evt.coordinate;
        const hdms = toStringHDMS(coordinate);

        window.x = coordinate[0];
        window.y = coordinate[1];

        //console.log(coordinate);
        //console.log(hdms);


        var popup_html = "";
        var popup_data = [];
        var popup_data_proj = [];
        var popup_data_risk = [];
        var popup_data_haz = [];
        var popup_data_vul = [];
        var popup_data_ssp126 = [];
        var popup_data_ssp245 = [];
        var popup_data_ssp585 = [];


        /////
        // On map click, loop all layers that are currently loaded at the selected click
        // then find out, which one is on top and check if its also the current active layer

        map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            //console.log(activeLayer);
            // whole layer, e.g oueme risk 2year
            //console.log(layer)
            // single clicked feature at location
            //console.log(feature)


            if (layer['values_']['title'].includes('synop')) {
                //console.log("SYNOP layer");

                if (activeLayer.title != layer['values_']['title']) {
                    //console.log("IS NOT ACTIVE LAYER");
                    return
                } else {

                    if (layerType == 'none') {
                        layerType = 'synop';
                    } else {
                        return
                    }

                    // Check if the clicked feature is from the correct layer
                    const data = feature.getProperties();
                    //console.log("Clicked Feature Data:", data); // Do something with the data

                    // create popup html
                    popup_html = "";
                    popup_html += 'Station-based<br>';
                    popup_html += 'extreme precipitation values';


                    if (activeLayer.type == 'recent') {
                        popup_data[0] = data['2'];
                        popup_data[1] = data['5'];
                        popup_data[2] = data['10'];
                        popup_data[3] = data['15'];
                        popup_data[4] = data['25'];
                        popup_data[5] = data['50'];

                    } else if (activeLayer.type == 'projection') {
                        popup_data_proj[0] = data['2'];
                        popup_data_proj[1] = data['5'];
                        popup_data_proj[2] = data['10'];
                        popup_data_proj[3] = data['15'];
                        popup_data_proj[4] = data['25'];
                        popup_data_proj[5] = data['50'];
                    }



                }



                /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // ------------------------------- RISK ----------------------------------------------------------------- //
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            } if (layer['values_']['title'].includes('risk')) {
                //console.log("RISK layer");

                if (activeLayer.title != layer['values_']['title']) {
                    //console.log("IS NOT ACTIVE LAYER");
                    return
                } else {

                    if (layer['values_']['title'].includes('oueme')) {
                        //console.log('OUEME');


                        if (layerType == 'none') {
                            layerType = 'risk';
                        } else {
                            return
                        }

                        // Check if the clicked feature is from the correct layer
                        const data = feature.getProperties();
                        //console.log("Clicked Feature Data:", data); // Do something with the data

                        // create popup html
                        popup_html = "";
                        popup_html += "Region: " + data.NAME + "<br>";
                        popup_html += "<br>";
                        popup_html += "<table class='table table-hover table-sm indicator-table risk-table' style='width:100%'><thead><tr class='table-dark table-head'><th scope='col' style='width: 33%'>Risk</th><th scope='col' style='width: 33%'>Hazard</th><th scope='col' style='width: 33%'>Vulnerability</th></tr></thead><tbody><tr class='table-primary table-row inactive'><td>" + data['risk'] + "</td><td>" + data['hazard'] + "</td><td>" + data['vul'] + "</td></tr></tbody></table>"


                        popup_data_risk[0] = data['risk'];
                        popup_data_haz[0] = data['hazard'];
                        popup_data_vul[0] = data['vul'];

                    } else if (layer['values_']['title'].includes('kumasi')) {
                        //console.log('KUMASI');


                        if (layerType == 'none') {
                            layerType = 'risk';
                        } else {
                            return
                        }

                        // Check if the clicked feature is from the correct layer
                        const data = feature.getProperties();
                        //console.log("Clicked Feature Data:", data); // Do something with the data

                        // create popup html
                        popup_html = "";
                        //popup_html += "Flooding Risk<br>";
                        popup_html += "Region: " + data.NAME + "<br>";
                        popup_html += "<br>";
                        popup_html += "<table class='table table-hover table-sm indicator-table risk-table' style='width:100%'><thead><tr class='table-dark table-head'><th scope='col' style='width: 33%'>Risk</th><th scope='col' style='width: 33%'>Hazard</th><th scope='col' style='width: 33%'>Vulnerability</th></tr></thead><tbody><tr class='table-primary table-row inactive'><td>" + data['risk'] + "</td><td>" + data['hazard'] + "</td><td>" + data['vul'] + "</td></tr></tbody></table>"

                        popup_data_risk[0] = data['risk'];
                        popup_data_haz[0] = data['hazard'];
                        popup_data_vul[0] = data['vul'];

                    }



                }


            } if (layer['values_']['title'].includes('Countries') || layer['values_']['title'].includes('Counties') || layer['values_']['title'].includes('Districts') || layer['values_']['title'].includes('Cities')) {
                layerType == 'none';
            } if (activeLayer.indicator == 'hazard' || activeLayer.class == 'return') {
                //console.log("OTHER RASTER LAYER");

                if (layerType == 'none') {
                    layerType = 'raster';
                } else {
                    return
                }

                return;
            }
        });

        //console.log(layerType)

        $('.ol-popup').show();
        // finish html and add to popup content
        content.innerHTML = popup_html;
        //
        overlay.setPosition(coordinate);
        map.addOverlay(overlay);

        //var ctx = document.getElementById('popupChartCanvas');
        var ctx = createCanvasPopup();
        //console.log(ctx);


        //console.log(layerType);
        //console.log(popup_data_risk);
        //console.log(popup_data_haz);
        //console.log(popup_data_vul);
        //console.log(popup_data_ssp126);
        //console.log(popup_data_ssp245);
        //console.log(popup_data_ssp585);


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ------------------------------- SYNOPTIC ----------------------------------------------------------------- //
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (layerType == 'synop') {

            //console.log("SYNOP LAYER");

            const labels = ['2-year', '5-year', '10-year', '15-year', '25-year', '50-year'];

            var chart_conf;


            //-----------------------------------------------------//
            //////////////////// RECENT ////////////////////////////
            //---------------------------------------------------//
            if (activeLayer.type == 'recent') {


                chart_conf = createBarChartConf('popup', 'synop');

                //console.log(popup_data);

                chart_conf.data = {
                    labels: labels,
                    datasets: [
                        {
                            label: "historic",
                            data: popup_data,
                            backgroundColor: '#4D9DF1',
                            borderColor: '#172E47',
                            borderWidth: 2,
                        }
                    ],
                }

                // // dont display legend
                chart_conf.options.plugins.legend.display = false;


            //---------------------------------------------------------//
            //////////////////// PROJECTION ////////////////////////////
            //-------------------------------------------------------//
            } else if (activeLayer.type == 'projection') {

                chart_conf = createBarChartConf('popup', 'synop', '', activeLayer.projectionType);

                //console.log(popup_data_proj);

                chart_conf.data =  {
                    labels: labels,
                    datasets: [
                        {
                            label: activeLayer.scenarioLong,
                            data: popup_data_proj,
                            backgroundColor: '#4D9DF1',
                            borderColor: '#172E47',
                            borderWidth: 2,
                        },
                    ],
                }

                // // dont display legend
                chart_conf.options.plugins.legend.display = false;



            }



            chart_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], activeLayer.getChartTitle()[1], "lon: " + Math.round(x * 1000) / 1000 + " lat: " + Math.round(y * 1000) / 1000];

            //console.log(chart_conf);

            setTimeout(function () {
                Chart.register(...registerables);
                window.chart_popup = new Chart(ctx, chart_conf);

            }, 100);

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ------------------------------- RISK --------------------------------------------------------------------- //
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        } else if (layerType == 'risk') {
            // Risk layers get no Chart
            // just the current values are displayed in a table

            //console.log("RISK LAYER");



        } else if (layerType = 'raster') {
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // ------------------------------- RETURN VALUE ----------------------------------------------------------------- //
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if (activeLayer.class == 'return') {
                //console.log("RETURN LAYER");
                // get coordinates and coordinates string
                const coordinate = evt.coordinate;

                window.x = coordinate[0];
                window.y = coordinate[1];

                //console.log(coordinate);

                var api = api_url + "/returnValues";

                switch (activeLayer.type) {
                    case 'recent':
                        var post_data = 'indicator=' + activeLayer.indicator + "&baseLevel=" + activeLayer.baseLevel + "&type=" + activeLayer.type + "&region=&admin=&plotRegion=popup&plotType=box" + "&x=" + x + "&y=" + y;
                        break;

                    case 'projection':
                        var post_data = 'indicator=' + activeLayer.indicator + "&baseLevel=" + activeLayer.baseLevel + "&type=" + activeLayer.type + "&projectionType=" + activeLayer.projectionType + "&projectionPeriod=" + activeLayer.projectionPeriod + "&scenario=" + activeLayer.scenario + "&region=&admin=&plotRegion=popup" + "&plotType=box" + "&x=" + x + "&y=" + y;
                        break;
                }

                //console.log(post_data);


                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    data: post_data,
                    url: api,
                    //username: 'user',
                    //password: 'pass',
                    crossDomain: true,
                    xhrFields: {
                        //withCredentials: true,
                    },
                })
                    // when post request is done
                    .done(function (data) {
                        //console.log('OUTPUT:');
                        //console.log(data);

                        Chart.register(...registerables);
                        ///// CHART DATA
                        // intialize chart data
                        // create year labels for plot
                        const labels = ['2-year', '5-year', '10-year', '25-year', '50-year'];


                        //--------------------------------------------------------------------------//
                        //////////////////// RECENT //////////////////////////////////////////////
                        //--------------------------------------------------------------------------//
                        if (activeLayer.type == 'recent') {

                            var chart_data = {
                                labels: labels,
                                datasets: [{
                                    label: "historic",
                                    data: data.slice(0, 5),
                                    backgroundColor: '#4D9DF1',
                                    borderColor: '#172E47',
                                    borderWidth: 2,
                                }],
                            };

                            //console.log(chart_data)

                            var chart_conf = createBarChartConf('popup', 'return');

                            chart_conf.data = chart_data;

                            // display legend
                            chart_conf.options.plugins.legend.display = false;

                            //--------------------------------------------------------------------------//
                            //////////////////// Projection //////////////////////////////////////////////
                            //--------------------------------------------------------------------------//
                        } else if (activeLayer.type == 'projection') {
                            var chart_data = {
                                labels: labels,
                                datasets: [{
                                    label: "ssp2-4.5",
                                    data: data.slice(0, 5),
                                    backgroundColor: '#4D9DF1',
                                    borderColor: '#172E47',
                                    borderWidth: 2,
                                },
                                {
                                    label: "ssp5-8.5",
                                    data: data.slice(6, 11),
                                    backgroundColor: '#ef9cF4',
                                    borderColor: '#172E47',
                                    borderWidth: 2,
                                }],
                            };

                            //console.log(chart_data)

                            var chart_conf = createBarChartConf('popup', 'return', '', activeLayer.projectionType);

                            chart_conf.data = chart_data;

                            // display legend
                            chart_conf.options.plugins.legend.display = true;
                        }







                        chart_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], activeLayer.getChartTitle()[1], "lon: " + Math.round(x * 1000) / 1000 + " lat: " + Math.round(y * 1000) / 1000];
                        //chart_conf.options.scales.y = y_axis_config;
                        //console.log(chart_conf);

                        //chart needs to be global, so it can be accessed when clicking create chart button
                        // so it can be destroyed if it already exists
                        setTimeout(function () {
                            Chart.register(...registerables);
                            window.chart_popup = new Chart(ctx, chart_conf);

                        }, 100);

                    })
                    // when request failed
                    .fail(function (xhr, textStatus, errorThrown) {
                        alert("Error creating timeseries chart.");
                        //console.log(xhr.responseText);
                        //console.log(textStatus);
                        //console.log(errorThrown);
                    });

            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // ------------------------------- HAZARD ----------------------------------------------------------------- //
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            } else if (activeLayer.class == 'catchment') {
                //console.log("HAZARD LAYER");
                // get coordinates and coordinates string
                const coordinate = evt.coordinate;

                window.x = coordinate[0];
                window.y = coordinate[1];

                //console.log(coordinate);

                var api = api_url + "/hazard";

                var post_data = 'indicator=' + activeLayer.indicator + "&event=" + activeLayer.event + "&catchment=" + activeLayer.catchment + "&region=&admin=&plotRegion=popup" + "&x=" + x + "&y=" + y;

                //console.log(post_data);


                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    data: post_data,
                    url: api,
                    //username: 'user',
                    //password: 'pass',
                    crossDomain: true,
                    xhrFields: {
                        //withCredentials: true,
                    },
                })
                    // when post request is done
                    .done(function (data) {
                        //console.log('OUTPUT:');
                        //console.log(data);


                        Chart.register(...registerables);
                        ///// CHART DATA
                        // intialize chart data
                        // create year labels for plot

                        var labels;

                        if (activeLayer.catchment == 'oueme') {
                            labels = ['2-year', '20-year', '100-year', '300-year'];
                        } else if (activeLayer.catchment == 'kumasi') {
                            labels = ['2 hours', '6 hours', '12 hours', '24 hours'];
                        }




                        var chart_data = {
                            labels: labels,
                            datasets: [{
                                label: activeLayer.indicator,
                                data: data,
                                backgroundColor: '#4D9DF1',
                                borderColor: '#172E47',
                                borderWidth: 2,
                            }],
                        };


                        //console.log(chart_data)

                        var chart_conf = createBarChartConf('popup', 'hazard', activeLayer.catchment);

                        chart_conf.data = chart_data;
                        chart_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], activeLayer.getChartTitle()[1], "lon: " + Math.round(x * 1000) / 1000 + " lat: " + Math.round(y * 1000) / 1000];
                        //chart_conf.options.scales.y = y_axis_config;
                        //console.log(chart_conf);


                        //chart needs to be global, so it can be accessed when clicking create chart button
                        // so it can be destroyed if it already exists
                        setTimeout(function () {
                            Chart.register(...registerables);
                            window.chart_popup = new Chart(ctx, chart_conf);

                        }, 100);

                    })
                    // when request failed
                    .fail(function (xhr, textStatus, errorThrown) {
                        alert("Error creating timeseries chart.");
                        //console.log(xhr.responseText);
                        //console.log(textStatus);
                        //console.log(errorThrown);
                    });


            }


        }




    } else if (info_btn.classList.contains("passive")) {
        //console.log("button inactive, no popup");
    }


    var chartCanvasPopup;

    // if downloadChartButtonPopupPopup is clicked
    $(".chart-wrapper-popup").on("click", "#downloadChartButtonPopup", function () {

        var chartContainer = $(this).closest('.chart-and-button-container-popup');
        chartCanvasPopup = chartContainer.find('.chart-canvas-popup');

        $('#downloadChartModalPopup').modal('toggle');
    });

    ////// actual buttons to download data or image
    $("#downloadChartImageBtnPopup").on("click", function () {

        //console.log(Chart);

        var chartInstance = Chart.getChart(chartCanvasPopup[0]);
        //console.log(chartCanvasPopup);
        //console.log(chartInstance);

        var title = chartInstance.options.plugins.title.text;
        var a = document.createElement('a');
        a.href = chartInstance.toBase64Image();
        a.download = 'timeseries ' + title + '.png';
        a.click();
        try {
            stopPropagation();
        } catch (error) {
        }

        // close modal
        //$('#downloadChartModal').modal('toggle');
    });

});










//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                            ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    COMPARE RETURN VALUES   ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                            //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//



$("#returnValuesChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    };

    if (activeLayer.class != 'return') {
        alert("Aggregated charts only possible for GPM-IMERG return values.");
        return;
    }

    var api = api_url + "/returnValues";



    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");


    //console.log(admin_selected);
    //console.log(region_selected_single);

    switch (activeLayer.type) {
        case 'recent':
            var post_data = 'indicator=' + activeLayer.indicator + "&baseLevel=" + activeLayer.baseLevel + "&type=" + activeLayer.type + "&region=" + region_selected_single + "&admin=" + admin_selected + "&plotRegion=panel";
            break;

        case 'projection':
            var post_data = 'indicator=' + activeLayer.indicator + "&baseLevel=" + activeLayer.baseLevel + "&type=" + activeLayer.type + "&projectionType=" + activeLayer.projectionType + "&projectionPeriod=" + activeLayer.projectionPeriod + "&scenario=" + activeLayer.scenario + "&region=" + region_selected_single + "&admin=" + admin_selected + "&plotRegion=panel";
            break;
    }

    //console.log(post_data);








    //###############################################################################################################///
    /////////////////////////////////////////     AJAX POST DATA         //////////////////////////////////////////////
    //##############################################################################################################//


    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        //username: 'user',
        //password: 'pass',
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            var ctx = createCanvas();




            //--------------------------------------------------------------------------//
            //////////////////// RECENT //////////////////////////////////////////////
            //--------------------------------------------------------------------------//
            if (activeLayer.type == 'recent') {

                Chart.register(BoxPlotController, BoxAndWiskers, ...registerables);

                const labels = ['2-year', '5-year', '10-year', '15-year', '25-year', '50-year'];

                var data_len = data.length;
                var num_periods = 12;
                ///// CHART DATA
                // intialize chart data
                // create year labels for plot

                var dataset_breaks = [];

                for (let i = 0; i <= num_periods; i++) {
                    dataset_breaks.push((data_len / num_periods) * i);
                }

                //console.log(dataset_breaks);
                var chart_data = {
                    labels: labels,
                    datasets: [
                        {
                            label: "historic",
                            data: [
                                (data.slice(dataset_breaks[0], dataset_breaks[1])),
                                (data.slice(dataset_breaks[1], dataset_breaks[2])),
                                (data.slice(dataset_breaks[2], dataset_breaks[3])),
                                (data.slice(dataset_breaks[3], dataset_breaks[4])),
                                (data.slice(dataset_breaks[3], dataset_breaks[5])),
                                (data.slice(dataset_breaks[5], dataset_breaks[6]))
                            ],
                            fill: false,
                            backgroundColor: '#4D9DF1',
                            borderColor: '#172E47',
                            borderWidth: 2,
                            tension: 0.1,
                        }
                    ],
                };


                //console.log(chart_data);


                // //console.log(chart_data)

                var chart_conf = createBoxPlotConf('panel');

                chart_conf.data = chart_data;

                chart_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], activeLayer.getChartTitle()[1], "in " + region_selected];;
                //chart_conf.options.scales.y = y_axis_config;
                //console.log(chart_conf);

                //--------------------------------------------------------------------------//
                //////////////////// PROJECTION //////////////////////////////////////////////
                //--------------------------------------------------------------------------//
            } else if (activeLayer.type == 'projection') {

                Chart.register(BoxPlotController, BoxAndWiskers, ...registerables);

                const labels = ['2-year', '5-year', '10-year', '15-year', '25-year', '50-year'];

                var data_len = data.length;
                var num_periods = 12;

                var dataset_breaks = [];

                for (let i = 0; i <= num_periods; i++) {
                    dataset_breaks.push((data_len / num_periods) * i);
                }

                //console.log(dataset_breaks);

                var chart_data = {
                    labels: labels,
                    datasets: [
                        {
                            label: "ssp2-4.5",
                            data: [
                                (data.slice(dataset_breaks[0], dataset_breaks[1])),
                                (data.slice(dataset_breaks[1], dataset_breaks[2])),
                                (data.slice(dataset_breaks[2], dataset_breaks[3])),
                                (data.slice(dataset_breaks[3], dataset_breaks[4])),
                                (data.slice(dataset_breaks[4], dataset_breaks[5])),
                                (data.slice(dataset_breaks[5], dataset_breaks[6]))
                            ],
                            fill: false,
                            backgroundColor: '#4D9DF1',
                            borderColor: '#172E47',
                            borderWidth: 2,
                            tension: 0.1,
                        },
                        {
                            label: "ssp5-8.5",
                            data: [
                                data.slice(dataset_breaks[6], dataset_breaks[7]),
                                data.slice(dataset_breaks[7], dataset_breaks[8]),
                                data.slice(dataset_breaks[8], dataset_breaks[9]),
                                data.slice(dataset_breaks[9], dataset_breaks[10]),
                                data.slice(dataset_breaks[10], dataset_breaks[11]),
                                data.slice(dataset_breaks[11], dataset_breaks[12])
                            ],
                            fill: false,
                            backgroundColor: '#ef9cF4',
                            borderColor: '#172E47',
                            borderWidth: 2,
                            tension: 0.1,
                        }
                    ],
                };


                //console.log(chart_data);


                // //console.log(chart_data)

                var chart_conf = createBoxPlotConf('panel');

                chart_conf.data = chart_data;

                chart_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], activeLayer.getChartTitle()[1], "in " + region_selected];;
                //chart_conf.options.scales.y = y_axis_config;
                //console.log(chart_conf);
            }



            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            window.timeseries_chart = new Chart(ctx, chart_conf);

            // disable loader
            $("#loaderContainer").hide();





        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });

})




//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                    ///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////        CHART BUTTONS  ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                    //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//

$(".chart-wrapper").on("click", ".destroy-chart-button", function () {
    //console.log("destory chart");
    //console.log(this);

    var chartContainer = $(this).closest('.chart-and-button-container');
    var chartCanvas = chartContainer.find('.chart-canvas');

    //console.log(chartContainer);
    //console.log(chartCanvas);

    // // Access your chart object (assuming it's linked to the canvas)
    // var chartToDestroy = chartCanvas.data('chartObject'); 
    // if (chartToDestroy) {
    //    chartToDestroy.destroy();
    // }

    // Remove the entire container
    chartContainer.remove();
    chartCanvas.remove();
})

// declare global variable, to save which chart was clicked
// to give these information to modal buttons
var chartCanvas;
// if downloadChartButton is clicked
$(".chart-wrapper").on("click", "#downloadChartButton", function () {

    var chartContainer = $(this).closest('.chart-and-button-container');
    chartCanvas = chartContainer.find('.chart-canvas');

    $('#downloadChartModal').modal('toggle');
});


////// actual buttons to download data or image
$("#downloadChartImageBtn").on("click", function () {
    //console.log(chartCanvas);

    var chartInstance = Chart.getChart(chartCanvas[0]);
    //console.log(chartCanvas);
    //console.log(chartInstance);

    var title = chartInstance.options.plugins.title.text;
    var a = document.createElement('a');
    a.href = chartInstance.toBase64Image();
    a.download = 'timeseries ' + title + '.png';
    a.click();
    try {
        stopPropagation();
    } catch (error) {
    }

});




