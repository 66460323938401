import $, { type } from "jquery";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


$(function () {

  // toggle modal on click on card
  $("#downloadCard").on("click", function () {
    $('#downloadDataModal').modal('toggle');
  })

  //////////////////////////////////////// DOWNLOAD MODAL UI LOGIC ////////////////////////////////////

  $('#downloadReturnTypeForm').on('input', function () {
    //console.log("input return")

    var type_checked = $('.form-check-input-return-type:checked').map(function () {
      return this.value;
    }).get();

    if (type_checked.includes('projection')) {
      $('#downloadReturnScenarioForm').show();
      $('#downloadFormReturnTypeProjection').show();
      $('#downloadFormReturnPeriodProjection').show();
    } else {
      $('#downloadReturnScenarioForm').hide();
      $('#downloadFormReturnTypeProjection').hide();
      $('#downloadFormReturnPeriodProjection').hide();
    }

  })


  $('#downloadSynopTypeForm').on('input', function () {
    //console.log("input synop")

    var type_checked = $('.form-check-input-synop-type:checked').map(function () {
      return this.value;
    }).get();

    if (type_checked.includes('projection')) {
      $('#downloadFormSynopTypeProjection').show();
      $('#downloadFormSynopPeriodProjection').show();
      $('#downloadSynopScenarioForm').show();
    } else {
      $('#downloadFormSynopTypeProjection').hide();
      $('#downloadFormSynopPeriodProjection').hide();
      $('#downloadSynopScenarioForm').show();
    }

  })




  // variables holding the selected data
  var indicators_checked = [];
  var baseLevel_checked = [];
  var returnType_checked = [];
  var projection_type_checked = [];
  var scenario_checked = [];
  var projection_period_checked = [];
  var indicators_full = [];


  $('#tab041').on('input', function () {


    indicators_checked = ['prec'];
    baseLevel_checked = ['gpm-imerg'];

    returnType_checked = $('.form-check-input-return-type:checked').map(function () {
      return this.value;
    }).get();
    projection_type_checked = $('.form-check-input-return-type-projection:checked').map(function () {
      return this.value;
    }).get();
    projection_period_checked = $('.form-check-input-return-period-projection:checked').map(function () {
      return this.value;
    }).get();
    scenario_checked = $('.form-check-input-return-scenario:checked').map(function () {
      return this.value;
    }).get();
    // period_checked = $('.form-check-input-return-period:checked').map(function () {
    //   return this.value;
    // }).get();


    //console.log(indicators_checked);
    //console.log(baseLevel_checked);
    //console.log(returnType_checked);
    //console.log(projection_type_checked);
    //console.log(projection_period_checked);
    //console.log(scenario_checked);
    // //console.log(period_checked);

  })



  var synop_type_checked = [];
  var synop_projection_type_checked = [];
  var synop_projection_period_checked = [];
  var synop_scenario_checked = [];

  $('#tab051').on('input', function () {

    synop_type_checked = $('.form-check-input-synop-type:checked').map(function () {
      return this.value;
    }).get();
    synop_projection_type_checked = $('.form-check-input-synop-type-projection:checked').map(function () {
      return this.value;
    }).get();
    synop_projection_period_checked = $('.form-check-input-synop-period-projection:checked').map(function () {
      return this.value;
    }).get();
    synop_scenario_checked = $('.form-check-input-synop-scenario:checked').map(function () {
      return this.value;
    }).get();

    

    //console.log(synop_type_checked);
    //console.log(synop_projection_type_checked);
    //console.log(synop_projection_period_checked);
    //console.log(synop_scenario_checked);

  })



  var risk_catchment_checked = [];

  $('#tab061').on('input', function () {

    risk_catchment_checked = $('.form-check-input-risk-catchment:checked').map(function () {
      return this.value;
    }).get();

    //console.log(risk_catchment_checked);

  })


  var hazard_catchment_checked = [];

  $('#tab071').on('input', function () {

    hazard_catchment_checked = $('.form-check-input-hazard-catchment:checked').map(function () {
      return this.value;
    }).get();

    //console.log(hazard_catchment_checked);

  })


  var shape_ending = ['.cpg', '.dbf', '.prj', '.shx', '.shp']

  $('#downloadIndicesButton').on('click', function () {

    //console.log("download button clicked");

    var tab_active = $('.fieldset-tab.show').attr('id');
    //console.log(tab_active);

    //open modal that lets user wait until everything is zipped
    // $('#downloadDataModal').css('background-color', '#000000');


    switch (tab_active) {
      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ RETURN VALUES DOWNLOAD -----------------------------------------//
      case 'tab041':
        for (let i = 0; i < indicators_checked.length; i++) {

          //console.log("IST EIN RETURN VALUE");
          var indicator = indicators_checked[i];
          //console.log(indicator);

          /////////// RECENT /////////////
          if (returnType_checked.includes('recent')) {
            //console.log("RECENT");

            for (let b = 0; b < baseLevel_checked.length; b++) {
              for (let a = 0; a < indicators_checked.length; a++) {
                var indicator = indicators_checked[a] + "_rvs_" + baseLevel_checked[b] + "_recent.tif";
                var indicator_path = 'furiflood/returnValues/' + indicators_checked[a] + "/" + indicator;
                //console.log(indicator_path);
                indicators_full.push(indicator_path);
              }
            }

          }
          ////////// PROJECTION /////////////
          if (returnType_checked.includes('projection')) {
            //console.log("PROJECTION");

            for (let e = 0; e < scenario_checked.length; e++) {
              for (let d = 0; d < projection_period_checked.length; d++) {
                for (let c = 0; c < projection_type_checked.length; c++) {
                  for (let b = 0; b < baseLevel_checked.length; b++) {
                    for (let a = 0; a < indicators_checked.length; a++) {
                      var indicator = indicators_checked[a] + "_rvs_" + baseLevel_checked[b] + "_projection_" + projection_type_checked[c] + "_" + projection_period_checked[d] + "_" + scenario_checked[e] + ".tif";
                      var indicator_path = 'furiflood/returnValues/' + indicators_checked[a] + "/" + indicator;
                      //console.log(indicator_path);
                      indicators_full.push(indicator_path);
                    }
                  }
                }
              }
            }
          }

        }


        break;


      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ SYNOPTIC DOWNLOAD -----------------------------------------//
      case 'tab051':
        //console.log("IST EIN SYNOP");

        /////////// RECENT /////////////
        if (synop_type_checked.includes('recent')) {

          var path = 'furiflood/synop/' + "prec_synop_recent";

          for (let x = 0; x < shape_ending.length; x++) {
            var shape_path = path + shape_ending[x];
            indicators_full.push(shape_path);
          }


        }
        /////////// Projection /////////////
        if (synop_type_checked.includes('projection')) {

          for (let a = 0; a < synop_scenario_checked.length; a++) {
          for (let c = 0; c < synop_projection_period_checked.length; c++) {
            for (let b = 0; b < synop_projection_type_checked.length; b++) {

              var path = 'furiflood/synop/' + "prec_synop_projection_" + synop_projection_type_checked[b] + "_" + synop_projection_period_checked[c]+ "_" + synop_scenario_checked[a];

              for (let x = 0; x < shape_ending.length; x++) {
                var shape_path = path + shape_ending[x];
                indicators_full.push(shape_path);
              }

            }
          }
        }

        }

        break;


      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ RISK DOWNLOAD -----------------------------------------//
      case 'tab061':
        //console.log("IST EIN RISK");

        for (let a = 0; a < risk_catchment_checked.length; a++) {
          var path = 'furiflood/risk/' + "risk_" + risk_catchment_checked[a];

          for (let x = 0; x < shape_ending.length; x++) {
            var shape_path = path + shape_ending[x];
            indicators_full.push(shape_path);
          }
        }

        break;

      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ HAZARD DOWNLOAD -----------------------------------------//
      case 'tab071':
        //console.log("IST EIN HAZARD");
        //console.log(hazard_catchment_checked);

        for (let a = 0; a < hazard_catchment_checked.length; a++) {

          if (hazard_catchment_checked[a] == 'oueme') {
            var path1 = 'furiflood/hazard/' + "hazard_hq2_oueme.tif";
            var path2 = 'furiflood/hazard/' + "hazard_hq20_oueme.tif";
            var path3 = 'furiflood/hazard/' + "hazard_hq100_oueme.tif";
            var path4 = 'furiflood/hazard/' + "hazard_hq300_oueme.tif";

            indicators_full.push(path1, path2, path3, path4);

          } else if (hazard_catchment_checked[a] == 'kumasi') {
            var path1_1 = 'furiflood/hazard/' + "hazard_hq2_2h_kumasi.tif";
            var path1_2 = 'furiflood/hazard/' + "hazard_hq2_6h_kumasi.tif";
            var path1_3 = 'furiflood/hazard/' + "hazard_hq2_12h_kumasi.tif";
            var path1_4 = 'furiflood/hazard/' + "hazard_hq2_24h_kumasi.tif";

            var path2_1 = 'furiflood/hazard/' + "hazard_hq10_2h_kumasi.tif";
            var path2_2 = 'furiflood/hazard/' + "hazard_hq10_6h_kumasi.tif";
            var path2_3 = 'furiflood/hazard/' + "hazard_hq10_12h_kumasi.tif";
            var path2_4 = 'furiflood/hazard/' + "hazard_hq10_24h_kumasi.tif";

            var path3_1 = 'furiflood/hazard/' + "hazard_hq100_2h_kumasi.tif";
            var path3_2 = 'furiflood/hazard/' + "hazard_hq100_6h_kumasi.tif";
            var path3_3 = 'furiflood/hazard/' + "hazard_hq100_12h_kumasi.tif";
            var path3_4 = 'furiflood/hazard/' + "hazard_hq100_24h_kumasi.tif";


            indicators_full.push(path1_1, path1_2, path1_3, path1_4, path2_1, path2_2, path2_3, path2_4, path3_1, path3_2, path3_3, path3_4);
          }



        }
        break;
    }


    //console.log("GESAMT:");
    //console.log(indicators_full);

    var fileURLs = indicators_full.map(i => '/indicators/' + i);

    //////////////// CHECK IF VALID ///////////////////
    // logic, that checks if necessary checkboxes are checked
    if (fileURLs.length == 0) {


      alert("Something went wrong selecting the data. Maybe not all neccessary checkboxes are selected?");

      indicators_full = [];
      fileURLs = [];


    } else {
      $('#zippingModal').modal('toggle');
      var zip = new JSZip();
      var count = 0;

      var loadFile = function (url) {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = "blob";

          xhr.onload = function () {
            if (xhr.status == 200) {
              var blob_url = URL.createObjectURL(xhr.response);
              var fileName = url.substring(url.lastIndexOf('/') + 1);
              //console.log(fileName);
              zip.file(fileName, xhr.response, { binary: true }); // add downloaded file to zip
              resolve();
            } else {
              reject("Failed to download file " + url);
            }
          };

          xhr.onerror = function () {
            reject("Network error");
          };

          xhr.send();
        });
      };

      var promises = fileURLs.map(loadFile);

      Promise.all(promises)
        .then(function () {
          zip.generateAsync({ type: "blob" })
            .then(function (content) {
              saveAs(content, "furiflood_indicator_download.zip");
              setTimeout(() => {
                $('#zippingModal').modal('toggle');
              }, 1000);
            });
        })
        .catch(function (error) {
          console.error(error);
        });


      //$('#zippingModal').modal('toggle');
      // $('#downloadDataModal').css('background-color', '#ffffff');
      indicators_full = [];
      fileURLs = [];

    }

  })

});