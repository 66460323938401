import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { Map, View } from 'ol';
import bootstrap from 'bootstrap'
import $ from "jquery";
import { Fill, Stroke, Circle, Style, Icon } from 'ol/style';

// layerswitcher
import LayerSwitcher from "ol-ext/control/LayerSwitcher"


// custom functions, variables
import { RasterLayer, map, riskStyleFunction, synopStyleFunction, SynopBlack, SynopGreen, SynopOrange, SynopBlue, SynopRed, roi_oueme, Cities } from './js/layers';



///////////////////////////// EXTERNAL JSON ///////////////////////////////
// load external json with units for legend display via ajax
var legend_units = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/legend_units.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

// load external json with long names for indicator abbrevations
var indicator_json = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/indicators.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

// external json with different long names for abbreviations
var indicator_class_json = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/indicator_class.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

//////////////////////////////////////////////////////////////////////////


////////////// Object that will hold the multiple instances of layers that will be loaded
var Layers = {};


function resetRasterLayer() {
  RasterLayer.getLayers().clear();
}


function loadScript(url, id, callback) {
  let script = document.createElement('script');
  script.type = 'module';
  script.src = url;
  script.id = id;

  // Optional: only run the callback once the script loads
  script.onload = () => {
    if (callback) {
      callback();
    }
  };

  document.head.appendChild(script);
}



// cities at points with labels should only be visible when 
// rasterlayer is visible, because otherwise it might intervene with 
// the basemap (also add cities when using imagery basemap)
function setCityVisibility() {

  //console.log("SET CITY VISIBILITY TRIGGERED");

  var rasterVis = false;

  RasterLayer.getLayers().forEach(function (layer) {
    //console.log(layer);

    // when layer is vector layer (e.g. synop, skip and go to next layer)
    if (layer instanceof TileLayer) {
      //console.log("is tile wms")
      // if layer and layergroup are visible, then raster is visible
      // if layergroup is not visible but layer is visible -> raster is also not visible
      if (layer.values_.visible === true && RasterLayer.values_.visible === true) {
        rasterVis = true;
      }
    } else if (layer instanceof Vector) {
      //console.log("is vector")
      rasterVis = false;
      return;
    }

  })

  //console.log(rasterVis);

  if (rasterVis === true) {
    //console.log("cities visible");
    Cities.setVisible(true);
  } else {
    //console.log("cities invisible");
    Cities.setVisible(false);
  }

}


// when a new layer is loaded, make every other layer invisible
// important beacause some rater layers (like ir) are not present at every pixel
// -> other layers behind would "peek through the holes"
function setLayersVisibility(activeLayer) {
  //console.log(activeLayer.title)

  RasterLayer.getLayers().forEach(function (layer) {
    //console.log(layer.values_.titleOrg);

    // when layer is vector layer (e.g. synop, skip and go to next layer)
    if (layer instanceof TileLayer) {
      //console.log("is tile wms")
      if (layer.values_.titleOrg != activeLayer.title) {
        layer.setVisible(false);
      }
    } else if (layer instanceof Vector) {
      //console.log("is vector")
      return;
    }


  })
}


// loops Layers object and returns active / active layer
// 
function getActiveLayer(Layers) {
  for (let key in Layers) {
    if (Layers[key].active) {
      return Layers[key];
    }
  }
}

function getImgDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function () {
      reject(new Error("Failed to load image"));
    };
    img.src = url;
  });
}

// function that changes the displayed chart analysis possibilities based
// on selected layer class (different chart types for climate, crop, season, drought)
function changeSelectionDisplay(indicator_class) {
  switch (indicator_class) {
    case "climate":
      //console.log('climate selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer").hide();
      $("#droughtTypeContainer").hide();
      $('#seasonTypeContainer').hide();
      break;
    case "drought":
      //console.log('drought selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer").hide();
      $('#seasonTypeContainer').hide();
      $("#droughtTypeContainer").show();
      break;
    case "crop":
      //console.log('crop selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer").slideDown();
      $("#droughtTypeContainer").hide();
      $('#seasonTypeContainer').hide();
      break;
    case "season":
      //console.log('season selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer").hide();
      $('#seasonTypeContainer').show();
      $("#droughtTypeContainer").hide();
      break;
  }
}


function changeChartDisplay(indicator_class, indicator_type) {
  switch (indicator_class) {
    case "climate":
      //console.log('climate selected');
      if (indicator_type == 'recent') {
        $('#cropChartsSection').hide();
        $('#droughtChartsSection').hide();
        $('#cardSectionCharts').show();
        $('#rainySeasonChartsSection').hide();
        $('#timeseriesChartButton').css('display', 'block');
        $('#compareChartButton').css('display', 'none');
      } else if (indicator_type == 'projection') {
        $('#cropChartsSection').hide();
        $('#droughtChartsSection').hide();
        $('#cardSectionCharts').show();
        $('#rainySeasonChartsSection').hide();
        $('#timeseriesChartButton').css('display', 'block');
        $('#compareChartButton').css('display', 'block');
      }
      break;
    case "drought":
      //console.log('drought selected');
      $('#rainySeasonChartsSection').hide();
      $('#cropChartsSection').hide();
      $('#droughtChartsSection').show();
      $('#cardSectionCharts').hide();
      break;
    case "crop":
      //console.log('crop selected');
      $('#cropChartsSection').show();
      $('#droughtChartsSection').hide();
      $('#cardSectionCharts').hide();
      $('#rainySeasonChartsSection').hide();
      break;
    case "season":
      //console.log('season selected');
      $('#rainySeasonChartsSection').show();
      $('#cropChartsSection').hide();
      $('#droughtChartsSection').hide();
      $('#cardSectionCharts').hide();
      break;
  }

}





//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////               ///////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// LAYER CLASSES ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////               //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//

class Layer {
  constructor(indicator, active = false) {
    this.indicator = indicator;
    this.indicator_long = indicator_json[this.indicator];
    // true, if it is the active layer == topmost visible layer
    // that is used for analysis
    this.active = active;
  }

  //////////////////////////////// CLASS FUNCTIONS ///////////////////////////////////
  /////////
  // function that sets this layer as active = true and all other layers ind Layer object as active = false
  setActive(active, Layers) {
    // if active = true, set all other layers false
    if (active) {
      for (let key in Layers) {
        if (Layers[key] !== this) {
          Layers[key].active = false;
        }
      }
    }
    // last, set this layer
    this.active = active;
  }

  /////////
  // get url for legend img from geoserver
  getLegendURL() {
    var layer = "furiflood:" + this.titleNoReturnPeriod;
    var url = "https://landsurf.geo.uni-halle.de/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=" + layer;
    return url;
  }

  /////////
  // get dimensions of legend image from geoserver
  getLegendImgDim() {
    return getImgDimensions(this.getLegendURL());
  }

  /////////
  // get unit for this layer
  getUnit() {
    if (this.projectionType == 'diff' && this.indicator == 'prec') {
      var layer_unit = legend_units['precdiff'];
    } else {
      var layer_unit = legend_units[this.indicator];
    }

    return layer_unit;
  }


  /////////
  // get formatted Title string for charts
  getChartTitle() {


    var chartTitle = [];
    var titleIndicator;
    var titleDefinition;

    // classes: return, catchment, synop

    if (this.class == 'return') {
      titleIndicator = indicator_json[this.indicator] + " " + indicator_json[this.baseLevel];

      if (this.type == 'recent') {
        titleDefinition = indicator_json[this.type] + ", " + this.projectionTypeLong;

      } else if (this.type == 'projection') {
        titleDefinition = indicator_json[this.type] + ", " + this.projectionTypeLong + ", " + indicator_json[this.projectionPeriod] + " " + indicator_json[this.scenario];
      }

    } else if (this.class == 'synop') {
      titleIndicator = indicator_json[this.indicator] + ", Station-based";

      if (this.type == 'recent') {
        titleDefinition = titleDefinition = indicator_json[this.type] + ", " + this.projectionTypeLong;

      } else if (this.type == 'projection') {
        titleDefinition = indicator_json[this.type] + ", " + this.projectionTypeLong + ", " + indicator_json[this.projectionPeriod] + " " + indicator_json[this.scenario];
      }

    } else if (this.class == 'catchment') {
      titleIndicator = indicator_json[this.indicator];
      titleDefinition = indicator_json[this.event] + ", " + indicator_json[this.catchment];
    }

    chartTitle[0] = titleIndicator;
    chartTitle[1] = titleDefinition;
    return chartTitle;
  }



  //////////
  // create projectionTypeLong for display e.g. in Legend
  setProjectionTypeLong() {
    switch (this.type) {
      case 'recent':
        this.projectionTypeLong = 'absolute values';
        break;
      case 'projection':
        switch (this.projectionType) {
          case 'abs':
            this.projectionTypeLong = 'absolute values';
            break;
          case 'diff':
            this.projectionTypeLong = 'difference to reference period';
            break;
          case 'iqr':
            this.projectionTypeLong = 'interquartile range (uncertainty)';
            break;
        }
    }
  }


}


///------------------------------------------------------------------------///
////////////////////////    RETURN VALUES SUBCLASS   //////////////////////
///------------------------------------------------------------------------///
class ReturnLayer extends Layer {
  //// characteristics
  constructor(indicator, dataType, baseLevel, type, projectionType = '', projectionPeriod = '', scenario = '', returnPeriod) {
    super(indicator);
    this.class = "return";
    this.dataType = dataType;
    this.baseLevel = baseLevel;
    this.type = type;
    this.projectionType = projectionType;
    this.projectionPeriod = projectionPeriod;
    this.scenario = scenario;
    this.returnPeriod = returnPeriod;
    this.setProjectionTypeLong();
    this.getTitle();
  }

  getTitle() {
    switch (this.type) {
      case "recent":
        this.title = this.indicator + "_" + this.baseLevel + "_" + this.type + "_" + this.returnPeriod;
        this.titleNoReturnPeriod = this.indicator + '_' + this.dataType + "_" + this.baseLevel + "_" + this.type;
        this.titleReadable = indicator_json[this.indicator] + " " + indicator_json[this.baseLevel] + " " + this.type;
        this.titleReadablePlot = [indicator_json[this.indicator] + " " + indicator_json[this.baseLevel] + " " + this.type];
        break;
      case "projection":
        this.title = this.indicator + "_" + this.baseLevel + "_" + this.type + "_" + this.projectionType + "_" + this.projectionPeriod + "_" + this.scenario + "_" + this.returnPeriod;
        this.titleNoReturnPeriod = this.indicator + '_' + this.dataType + "_" + this.baseLevel + "_" + this.type + "_" + this.projectionType + "_" + this.projectionPeriod + "_" + this.scenario;
        this.titleReadable = indicator_json[this.indicator] + " " + indicator_json[this.baseLevel] + " " + this.type + " " + this.projectionTypeLong + " " + indicator_json[this.projectionPeriod] + " " + indicator_json[this.scenario];
        this.titleReadablePlot = [indicator_json[this.indicator] + " " + indicator_json[this.baseLevel] + " " + this.type, this.projectionTypeLong + " " + indicator_json[this.projectionPeriod] + " " + indicator_json[this.scenario]];
        break;
    }
  }



  // /////////
  // // generate new wms tilelayer from geoserver
  getGeoserverLayer() {
    // new tile wms layer from geoserver
    var newLayer = new TileLayer({
      title: this.title,
      titleOrg: this.title,
      indicatorClass: this.class,
      info: indicator_json[this.indicator] + ' - ' + this.projectionTypeLong + ': ' + this.title,
      visible: true,
      source: new TileWMS({
        url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/wms",
        params: {
          VERSION: "1.1.1",
          STYLES: "",
          LAYERS: "furiflood:" + this.titleNoReturnPeriod,
          exceptions: "application/vnd.ogc.se_inimage",
          TIME: this.returnPeriod,
          TILED: true
        },
        ratio: 1,
        serverType: "geoserver",
        crossOrigin: "",
      }),
    });

    var newLayerSource = newLayer.getSource();
    this.layerSource = newLayerSource;

    //console.log(newLayer);
    return newLayer;
  }


  /////////
  // add legend to card
  addLegend() {
    // create html string
    var appendstr =
      '<li><div id=legendImgDiv><p class="legend-title"><b>' +
      indicator_json[this.indicator] +
      "<br>" +
      this.projectionTypeLong +
      "<br>" +
      indicator_json[this.projectionPeriod] +
      "<br>" +
      indicator_json[this.scenario] +
      "<br>" +
      this.getUnit() +
      '<br></b></p><img class="legend-img" src="' +
      this.getLegendURL() +
      '" /></div></li>';

    // get width and height of legend and save it
    this.legendWidth = $('.legend-img').width();
    this.legendHeight = $('.legend-img').height();

    // add to legend card
    $('.legend-list').empty();
    $(".legend-list").append(appendstr);
  }

}


///------------------------------------------------------------------------///
////////////////////////    CATCHMENT LAYERS SUBCLASS   //////////////////////
///------------------------------------------------------------------------///
class CatchmentLayer extends Layer {
  //// characteristics
  constructor(indicator, event, catchment, duration = '') {
    super(indicator);
    this.class = "catchment";
    this.event = event;
    this.catchment = catchment;
    this.duration = duration;
    this.getDataType(indicator);
    this.getTitle();
  }

  // datatype dependend on indicator type
  getDataType(indicator) {
    switch (indicator) {
      case 'hazard':
        this.dataType = 'rast';
        break;
      case 'risk':
        this.dataType = 'vect';
        break;
      default:
        alert('Error selecting datatype of CatchmentLayer Indicator Type');
        break;
    }
  }

  getTitle() {

    switch (this.catchment) {
      case 'oueme':
        this.title = this.indicator + "_" + this.event + "_" + this.catchment;
        this.titleReadable = indicator_json[this.indicator] + " " + indicator_json[this.event] + " " + indicator_json[this.catchment];
        this.titleReadablePlot = [indicator_json[this.indicator] + " " + indicator_json[this.event] + " " + indicator_json[this.catchment]];
        break;
      case 'kumasi':

        if (this.indicator == 'hazard') {
          this.title = this.indicator + "_" + this.event + "_" + this.duration + "_" + this.catchment;
          this.titleReadable = indicator_json[this.indicator] + " " + indicator_json[this.event] + " " + this.duration + " " + indicator_json[this.catchment];
          this.titleReadablePlot = [indicator_json[this.indicator] + " " + indicator_json[this.event] + " " + this.duration + " " + indicator_json[this.catchment]];
        } else if (this.indicator == 'risk') {
          this.title = this.indicator + "_" + this.event + "_" + this.catchment;
          this.titleReadable = indicator_json[this.indicator] + " " + indicator_json[this.event] + " " + indicator_json[this.catchment];
          this.titleReadablePlot = [indicator_json[this.indicator] + " " + indicator_json[this.event] + " " + indicator_json[this.catchment]];
        }

        break;
    }

    //hazard_hq2_oueme

  }



  /////////////////////////////////
  /////// generate new layer from geoserver, distinct between hazard (raster) and risk (vector)
  getGeoserverLayer() {
    var newLayer;

    switch (this.dataType) {
      ////////////////////// RASTER TILEWMS LAYER
      case 'rast':
        newLayer = new TileLayer({
          title: this.title,
          titleOrg: this.title,
          indicatorClass: this.class,
          visible: true,
          source: new TileWMS({
            url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/wms",
            params: {
              VERSION: "1.1.1",
              STYLES: "",
              LAYERS: "furiflood:" + this.title,
              exceptions: "application/vnd.ogc.se_inimage",
              TILED: true
            },
            ratio: 1,
            serverType: "geoserver",
            crossOrigin: "",
          }),
        })
        break;

      ////////////////////// VECTOR GEOJSON LAYER
      case 'vect':
        newLayer = new Vector({
          title: this.title,
          visible: true,
          style: riskStyleFunction,
          source: new VectorSource({
            url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=furiflood%3A" + this.title + "&maxFeatures=50&outputFormat=application%2Fjson",
            format: new GeoJSON(),
          })
        })
        break;
    }

    return newLayer;
  }

  getLegendURL() {
    var layer = "furiflood:" + this.title;
    var url = "https://landsurf.geo.uni-halle.de/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=" + layer;
    return url;
  }

  /////////
  // add legend to card
  addLegend() {
    let legendClass;
    switch (this.indicator) {
      case 'hazard':
        legendClass = '<br></b></p><img class="legend-img" src="';
        break;
      case 'risk':
        legendClass = '<br></b></p><img class="legend-img-risk" src="';
        break;
    }


    // create html string
    var appendstr =
      '<li><div id=legendImgDiv><p class="legend-title"><b>' +
      this.indicator +
      "<br>" +
      indicator_json[this.indicator] +
      "<br>" +
      this.getUnit() +
      legendClass +
      this.getLegendURL() +
      '" /></div></li>';

    // get width and height of legend and save it
    this.legendWidth = $('.legend-img').width();
    this.legendHeight = $('.legend-img').height();



    // add to legend card
    $('.legend-list').empty();
    $(".legend-list").append(appendstr);
  }


}


///------------------------------------------------------------------------///
////////////////////////    SYNOPTIC STATIONS SUBCLASS   //////////////////////
///------------------------------------------------------------------------///
class SynopLayer extends Layer {
  //// characteristics
  constructor(indicator, dataType, type, projectionType = '', projectionPeriod = '', scenario = '', returnPeriod) {
    super(indicator);
    this.class = "synop";
    this.dataType = dataType;
    this.type = type;
    this.projectionType = projectionType;
    this.projectionPeriod = projectionPeriod;
    this.scenario = scenario;
    this.returnPeriod = 'hq' + returnPeriod;
    this.scenarioLong = indicator_json[this.scenario];
    this.projectionPeriodLong = indicator_json[this.projectionPeriod];
    // this.scenario = scenario;
    this.getTitle();
    this.setProjectionTypeLong();
  }

  getTitle() {
    switch (this.type) {
      case "recent":
        this.title = this.indicator + "_" + this.dataType + "_" + this.type + "_" + this.returnPeriod;
        this.titleNoReturnPeriod = this.indicator + '_' + this.dataType + "_" + this.type;
        this.titleReadable = this.indicator + " " + this.type + " " + this.returnPeriod;
        break;
      case "projection":
        this.title = this.indicator + "_" + this.dataType + "_" + this.type + "_" + this.projectionType + "_" + this.projectionPeriod + '_' + this.scenario + "_" + this.returnPeriod;
        this.titleNoReturnPeriod = this.indicator + '_' + this.dataType + "_" + this.type + "_" + this.projectionType + "_" + this.projectionPeriod + '_' + this.scenario;
        this.titleReadable = this.indicator + " " + this.type + " " + this.projectionType + " " + this.projectionPeriod + ' ' + this.scenario + " " + this.returnPeriod;
        break;
    }
  }



  // /////////
  // // generate new wms tilelayer from geoserver
  getGeoserverLayer() {

    // new geojson vector layer from geoserver
    var newLayer = new Vector({
      title: this.title,
      indicatorClass: this.class,
      titleOrg: this.title,
      info: indicator_json[this.indicator] + ' - ' + this.projectionTypeLong + ': ' + this.title,
      visible: true,
      style: synopStyleFunction,
      source: new VectorSource({
        url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=furiflood%3A" + this.title + "&maxFeatures=200&outputFormat=application%2Fjson",
        format: new GeoJSON(),
      })
    })

    var newLayerSource = newLayer.getSource();
    this.layerSource = newLayerSource;

    //console.log(newLayer);
    return newLayer;
  }



  /////////
  // get url for legend 
  // this time no geoserver because it is styled inside the web portal
  // getLegendURL() {

  getLegendURL() {
    var layer = "furiflood:" + this.title;
    var url = "https://landsurf.geo.uni-halle.de/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=" + layer;
    //console.log(url);
    return url;
  }


  /////////
  // add legend to card
  addLegend() {
    // create html string
    var appendstr =
      '<li><div id=legendImgDiv><p class="legend-title"><b>' +
      indicator_json[this.indicator] +
      "<br>" +
      this.projectionTypeLong +
      "<br>" +
      indicator_json[this.projectionPeriod] +
      "<br>" +
      indicator_json[this.scenario] +
      "<br>" +
      this.getUnit() +
      '<br></b></p><img class="legend-img" src="' +
      this.getLegendURL() +
      '" /></div></li>';

    // get width and height of legend and save it
    this.legendWidth = $('.legend-img').width();
    this.legendHeight = $('.legend-img').height();

    // add to legend card
    $('.legend-list').empty();
    $(".legend-list").append(appendstr);
  }

}




//#######################################################################################################################################################//
///////////////////////////////////////////////////////////////                     ///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////// WEBSITE LOAD READY  ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////                      //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//



// popup needs display:block at beginning to be initialized when page is loaded
$('.ol-popup').css('display', 'block');


$(function () {

  // now it can be switched back to display:none
  $('.ol-popup').css('display', 'none');

  $('#signalCardContainer').css('display', 'none');

  //####################################################################################################################//
  ///////////////////////////////////////////////// INITIALIZE LAYERSWITCHER ////////////////////////////////////////////

  // var toc = document.getElementById('layerSwitcherContainer');
  // LayerSwitcher.renderPanel(map, toc, { reverse: true });
  var target = $('#layerSwitcherContainer').get(0);
  //console.log(target);
  // Add a layer switcher outside the map
  var switcher = new LayerSwitcher({
    target: target,
    show_progress: false,
    extent: false,
    trash: true,
    reordering: false,
  });


  // Add a new ? button to the list that is used as info button for indicator layers
  // and displays the full readable layer name
  //
  // also remove removeLayer button (trash) from all layers except single indicators
  // so they cannot be removed from the map
  //
  // also tried to modify it directly in the source of layerswitcher or overwrite
  // the specific layerswitcher functions but i couldnt get that to work so now this workaround

  // everytime list of layers changes, loop all
  switcher.on('drawlist', function (e) {

    ////console.log(e);
    // get layer and title of layer
    var layer = e.layer;
    var title = layer.values_.title

    // do not add '?' at top level layers
    if (['Boundaries', 'Indicators', 'Base Maps', 'Cities'].some(item => title.includes(item))) {

      // find all layerTrash buttons from the top level layers
      // only take the first one ([0]) -> is the one associated with top level layer / layer group
      // remove these, others (from single indicator layers) will stay
      $(e.li).find('.ol-layerswitcher-buttons .layerTrash')[0].remove();

      return null;

    } else {
      // add '?' button, on click, toggle info modal with info stored in openlayers layer
      $('<div>').text('?').on('click', function () {
        $('#layerInfoModalBody').text(layer.get('info'));
        $('#layerInfoModal').modal('toggle');
      })
        .appendTo($('> .ol-layerswitcher-buttons', e.li));
    }

  });

  // add layerswitcher to map
  map.addControl(switcher);


  // Get options values
  if ($("#opb").prop("checked")) $('body').addClass('hideOpacity');
  if ($("#percent").prop("checked")) $('body').addClass('showPercent');
  if ($("#dils").prop("checked")) displayInLayerSwitcher(true);


  //####################################################################################################################//
  ////////////////////////////////////// LEGEND CHANGE ON LAYERSWITCHER CLICK ///////////////////////////////////////////
  ///////////////////////////////////////////// ON REMOVE LAYER CLICK ///////////////////////////////////////////////////

  /////// also check legend every time something in layerswitcher is checked
  // change legend if currently visible layer changes -> change currently active layer
  // on change, so that you get the visibility after the change / input is done
  // check for removing layer on 'x' click -> remove layer also from Layers object

  $('#layersCardContainer').on('click', function () {
    // everything 500ms after on layers card is clicked. because it takes a bit to expand
    // and the contents of layerswitcher are created during runtime
    setTimeout(function () {
      // if layers card is expanded
      if ($('#layers').hasClass('show')) {

        //////////////////////////////// LEGEND CHANGE LOGIC /////////////////////////////////////
        // get label of layer in layerswitcher --> this is where the user clicks when 
        // he is selecting / deselecting a layer
        var layerLabel = $('#layerSwitcherContainer').find('label');

        // on click, loop RasterLayer object that holds all current indicators
        $(layerLabel).on('click', function (event) {
          //console.log(layerLabel);
          //console.log(event);
          //console.log($(this));
          var lyr_title_org = $(this).text().replace(/\s+/g, "_");;
          //console.log(lyr_title_org);
          //console.log(Layers[lyr_title_org]);

          RasterLayer.getLayers().forEach(function (layer) {

            // detect last visible layer -> will be the currently visible layer
            if (layer["values_"]["visible"]) {
              var lyr_title = layer.values_.titleOrg;
              //console.log(lyr_title);
              // get equivalent layer from Layers object
              let activeLayer = Layers[lyr_title];

              // add legend of corresponding layer
              activeLayer.addLegend();

              // change active layer in Layers Object
              activeLayer.setActive(true, Layers);
              ////console.log(Layers);

              changeChartDisplay(activeLayer.class, activeLayer.type);

            }
          })
          // set city visibility, so e.g. not visible when no raster layer is present
          setCityVisibility();
        })
      }
    }, 500);
  })




  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////               ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////   LOAD LAYER  ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////               //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//

  // action when clicking load layer button
  $("#loadLayerButton, #loadCatchmentDataButton").on("click", function () {

    //console.log(this);
    // set flag, if layer is created
    // later on check if it was successful created, if not break out of click function completely
    var layerCreationSuccessful = true;
    // set flag if layer is called from catchment analysis or regular layer selection
    var regularData;
    switch ($(this).attr('datatype')) {
      case 'regular':
        regularData = true;
        break;
      case 'catchment':
        regularData = false;
        break;
    }

    var indicator;
    var dataType = '';
    var event;
    var duration;
    var catchment;
    var baseLevel;
    var type;
    var projectionType;
    var projectionPeriod;
    var scenario;
    var returnPeriod;



    if (regularData) {
      // get indicator from input text that was created earlier on when indicator selection modal was closed

      ////// layer class attributes
      //let indicator_parts = $("#indicatorSelectInput").val().split('-');
      //indicator = indicator_parts[0].trim(); JUST ONE INDICATOR USED ATM -> SET INDICATOR STATIC TO PREC
      indicator = 'prec';
      dataType = $('#dataTypeInput').val();
      //baseLevel = $('#baseLevelInput').val(); BASE LEVEL NOT USED ANYMORE, ALL RETURN VALUES ARE GMP-IMERG
      baseLevel = 'gpm-imerg';
      type = $('#indicatorTypeInput').val();
      projectionType = $('#projectionTypeInput').val();
      projectionPeriod = $('#projectionPeriodInput').val();
      scenario = $('#scenarioInput').val();
      returnPeriod = $('#returnPeriodInput').val();
    } else {
      ////// layer class attributes
      indicator = $("#catchmentDataInput").val();
      event = $("#catchmentEventInput").val();
      catchment = $("#catchmentInput").val();
      duration = $('#catchmentDurationInput').val();
    }



    //console.log(indicator);
    //console.log(dataType)
    //console.log(event);
    //console.log(catchment);
    //console.log(duration);
    //console.log(baseLevel);
    //console.log(type);
    //console.log(projectionType);
    //console.log(projectionPeriod);
    //console.log(scenario);
    //console.log(returnPeriod);


    // determine, if indicator to be loaded is return value, synop or catchment indicator
    // and then add the corresponding class
    var indicator_type = indicator + "_" + dataType;
    switch (indicator_class_json[indicator_type]) {
      // if layer is selected from climate table, its climate or drought
      case "return":
        //console.log("return")
        var newLayerInstance = new ReturnLayer(indicator, dataType, baseLevel, type, projectionType, projectionPeriod, scenario, returnPeriod, ReturnLayer)
        break;
      case "synop":
        //console.log("synop")
        var newLayerInstance = new SynopLayer(indicator, dataType, type, projectionType, projectionPeriod, scenario, returnPeriod, ReturnLayer)
        break;
      case "catchment":
        //console.log("catchment")
        var newLayerInstance = new CatchmentLayer(indicator, event, catchment, duration);
        break;
    }

    // test if new Layer already exists (if title is already a key in Layers)
    function hasLayer(title) {
      return Object.hasOwnProperty.call(Layers, title);
    }

    function createLayer(newLayer) {
      //console.log("CREATING NEW LAYER");
      // if layer does not exist, add to object
      if (!hasLayer(newLayer.title)) {
        //console.log("LAYER DOES NOT EXIST");

        // only allow one synop layer at a time, because they are point data above each other
        // if there is another synop layer, remove it and load only new selected one
        if (newLayer.class == 'synop') {
          //console.log("SYNOP");

          RasterLayer.getLayers().forEach(function (layer) {
            //console.log(layer);
            //console.log(layer.values_.title);

            if (layer.values_.title.includes('synop')) {
              RasterLayer.getLayers().remove(layer);
              layer.dispose();
            }
          })

          // set new layer active = true and all others false, because new layer will be visible on top --> active layer
          newLayer.setActive(true, Layers);
          Layers[newLayer.title] = newLayer;
          // set creation successful to true
          layerCreationSuccessful = true;
          return Layers;

        } else {
          //console.log("ELSE");
          // set new layer active = true and all others false, because new layer will be visible on top --> active layer
          newLayer.setActive(true, Layers);
          // add new layer to Layers object
          Layers[newLayer.title] = newLayer;
          // set creation successful to true
          layerCreationSuccessful = true;
          return Layers;
        }

        // if layer already exists, thorw error and do not add
      } else {
        // set creation successful to false
        //console.log(newLayer.title);
        //console.log(Layers[newLayer.title])
        // remove layer from Layers array
        delete Layers[newLayer.title];

        var removeLayer;
        // remove layer from RasterLayer Group
        // loop all existing layers and get the one that gets replaced
        RasterLayer.getLayers().forEach(function (layer) {
          //console.log(layer);

          // if layer from group has same title as new one
          // its the same layer -> remove it
          if (layer.values_.title == newLayer.title) {
            //console.log("this one gets removed");
            removeLayer = layer;
          }
        })

        RasterLayer.getLayers().remove(removeLayer);
        try {
          removeLayer.dispose();
        } catch (error) {
          //console.log('already disposed');
        }

        // add layer new to map
        // set new layer active = true and all others false, because new layer will be visible on top --> active layer
        newLayer.setActive(true, Layers);
        // add new layer to Layers object
        Layers[newLayer.title] = newLayer;
        // set creation successful to true
        layerCreationSuccessful = true;
        return Layers;


        // layerCreationSuccessful = false;
        // return null;
      }
    }

    //console.log(newLayerInstance);
    createLayer(newLayerInstance);


    // write new layer to variable to be able  
    // to zoom to its extent later
    var newGeoserverLayer;
    // test if the new layer was created, or not (e.g. if it already existed)
    // if it was not created successfully, skip following code so new layer from geoserver is not loaded
    if (layerCreationSuccessful) {

      //console.log("creation successful");

      //// change analysis card dependend on active layer
      let activeLayer = getActiveLayer(Layers);

      // add new openlayers Layer to RasterLayer Array
      newGeoserverLayer = newLayerInstance.getGeoserverLayer();
      RasterLayer.getLayers().push(newGeoserverLayer);

      // add Legend for new Layer if its not synoptic
      // if (activeLayer.dataType != 'synop') {
      //   newLayerInstance.addLegend();
      // }

      newLayerInstance.addLegend();


      //console.log(RasterLayer.getLayersArray());


      // expand legend card
      $('#legendLink').removeClass('inactive collapsed');
      $('#legendLink').addClass('clicked');
      $('#legendLink').attr('aria-expanded', 'true');
      $('#properties').addClass('show');

      $("#legendCard").attr('style', "border-radius: 0.25rem 0.25rem 0 0 !important");

      // hide layer overview card 
      $('#layerLink').attr('inactive');
      $('#layerLink').attr('collapsed');
      $('#layerLink').removeClass('clicked');
      $('#layerLink').attr('aria-expanded', 'false');
      $('#layers').removeClass('show');


      //changeChartDisplay(activeLayer.class, activeLayer.type);


      // ZOOM TO LAYER IF ITS CATCHMENT LAYER
      if (!regularData) {
        //console.log("catchment layer");
        //console.log(newGeoserverLayer);
        //console.log(newGeoserverLayer.getSource().getState());

        if (activeLayer.indicator == 'risk') {
          // Listen for the data to be loaded
          newGeoserverLayer.getSource().once('change', function () {
            //console.log("change");
            if (newGeoserverLayer.getSource().getState() === 'ready') { // Check if data is ready
              //console.log('layer load ready');
              var extent = newGeoserverLayer.getSource().getExtent();
              //console.log(extent);
              map.getView().fit(extent, map.getSize());
            }
          });
        } else if (activeLayer.indicator == 'hazard') {

          if (activeLayer.catchment == 'oueme') {
            if (newGeoserverLayer.getSource().getState() === 'ready') { // Check if data is ready
              //console.log('layer load ready');
              var extent = [1.1647984035416048, 6.476773503692882, 3.439541107832671, 7.988678763566726];
              map.getView().fit(extent, map.getSize());
            }

          } else if (activeLayer.catchment == 'kumasi') {
            if (newGeoserverLayer.getSource().getState() === 'ready') { // Check if data is ready
              //console.log('layer load ready');
              //var extent = [-5.3, 6.4, 2, 7];
              var extent = [-1.767826670644077, 6.598637544235931, -1.4526019456587314, 6.808151314232244]
              map.getView().fit(extent, map.getSize());

              // map.getView().animate({
              //   zoom: map.getView().getZoom() + 4,
              //   duration: 500,
              // })
            }
          }

        }
        //console.log(newGeoserverLayer.getSource().getState());
      }


    }

    // set city visibility, so e.g. not visible when no raster layer is present
    setCityVisibility();
    setLayersVisibility(getActiveLayer(Layers));
  });


  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////               ///////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////// SELECT REGION ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////               //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//

  //////// SEE SELECT_REGION.JS



  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////              ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////   ANALYSIS   ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////              //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//
 
  /////////// SEE ANALYSIS.JS


  $('#getextent').on('click', function () {

    // Get the map view object
    const view = map.getView();

    // Calculate the extent based on the current map size
    const extent = view.calculateExtent(map.getSize());

    //console.log(extent);
  })





});




export { loadScript, changeSelectionDisplay, indicator_class_json, Layers, getActiveLayer };

// use changeSelectionDisplay in indicator_table.js to change the
// selection containers shown when a new indicator is selected