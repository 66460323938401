import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Group from 'ol/layer/Group';
import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML';
import XYZ from 'ol/source/XYZ';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import Text from 'ol/style/Text';
import { defaults as defaultControls } from 'ol/control.js';
import { Fill, Stroke, Circle, Style, Icon } from 'ol/style';
import { _setMinAndMaxByKey } from 'chart.js/helpers';



////////// styles
const CountriesStyle = new Style({
    fill: new Stroke({
        color: "transparent",
    }),
    stroke: new Stroke({
        color: "black",
        width: 2,
    }),
});

const CountiesStyle = new Style({
    fill: new Stroke({
        color: "transparent",
    }),
    stroke: new Stroke({
        color: "#6c757d",
        width: 2,
    }),
});

const DistricsStyle = new Style({
    fill: new Stroke({
        color: "transparent",
    }),
    stroke: new Stroke({
        color: "#6c757d",
        width: 1,
    }),
});

const CatchmentStyle = new Style({
    fill: new Stroke({
        color: "#fee7d795",
    }),
    stroke: new Stroke({
        color: "#9b1913",
        width: 2,
    }),
});



const PointStyle = new Style({
    image: new Circle({
        fill: new Fill({
            color: [0, 0, 255, 0.8] // Blue with some transparency (change alpha for opacity)
        }),
        stroke: new Stroke({
            color: [255, 0, 0, 1], // Red
            width: 2
        }),
        radius: 5 // Adjust the radius as needed
    })
});


const SynopBlack = new Style({
    image: new Icon({
        src: './assets/wireless_station_black.png', // Replace with the correct path
        scale: 0.17, // Adjust the scale if needed
        anchor: [0.5, 0.5], // Center the image 
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
    })
});

const SynopGreen = new Style({
    image: new Icon({
        src: './assets/wireless_station_green.png', // Replace with the correct path
        scale: 0.17, // Adjust the scale if needed
        anchor: [0.5, 0.5], // Center the image 
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
    })
});

const SynopOrange = new Style({
    image: new Icon({
        src: './assets/wireless_station_orange.png', // Replace with the correct path
        scale: 0.17, // Adjust the scale if needed
        anchor: [0.5, 0.5], // Center the image 
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
    })
});

const SynopBlue = new Style({
    image: new Icon({
        src: './assets/wireless_station_blue.png', // Replace with the correct path
        scale: 0.17, // Adjust the scale if needed
        anchor: [0.5, 0.5], // Center the image 
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
    })
});

const SynopRed = new Style({
    image: new Icon({
        src: './assets/wireless_station_red.png', // Replace with the correct path
        scale: 0.17, // Adjust the scale if needed
        anchor: [0.5, 0.5], // Center the image 
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
    })
});


function synopStyleFunction(feature) {

    const precValue = feature.get('prec_org'); // Assuming 'prec_org' is the attribute name
    var name = feature['id_'];

    let fillColor;

    if (name.includes('diff') || name.includes('iqr')) {
        if (precValue >= -5 && precValue <= 0) {
            fillColor = '#ffffff';
        } else if (precValue > 0 && precValue <= 10) {
            fillColor = '#fee6db';
        } else if (precValue > 10 && precValue <= 20) {
            fillColor = '#fdd1be';
        } else if (precValue > 20 && precValue <= 30) {
            fillColor = '#fcb79c';
        } else if (precValue > 30 && precValue <= 40) {
            fillColor = '#fc9a7c';
        } else if (precValue > 40 && precValue <= 50) {
            fillColor = '#fc7e5e';
        } else if (precValue > 50 && precValue <= 60) {
            fillColor = '#f96144';
        } else if (precValue > 60 && precValue <= 70) {
            fillColor = '#f0402f';
        } else if (precValue > 70 && precValue <= 80) {
            fillColor = '#da2623';
        } else if (precValue > 80 && precValue <= 90) {
            fillColor = '#bf151a';
        } else if (precValue > 90 && precValue <= 100) {
            fillColor = '#9f0e14';
        } else if (precValue > 100) {
            fillColor = '#67000d';
        }
    } else {
        if (precValue <= 60) {
            fillColor = '#f7fbff';
        } else if (precValue > 60 && precValue <= 80) {
            fillColor = '#e8f2fa';
        } else if (precValue > 80 && precValue <= 100) {
            fillColor = '#dae8f6';
        } else if (precValue > 100 && precValue <= 120) {
            fillColor = '#cbdff1';
        } else if (precValue > 120 && precValue <= 140) {
            fillColor = '#b7d5ea';
        } else if (precValue > 140 && precValue <= 160) {
            fillColor = '#a0cbe2';
        } else if (precValue > 160 && precValue <= 180) {
            fillColor = '#82bbdb';
        } else if (precValue > 180 && precValue <= 200) {
            fillColor = '#65aad4';
        } else if (precValue > 200 && precValue <= 220) {
            fillColor = '#4d99ca';
        } else if (precValue > 220 && precValue <= 240) {
            fillColor = '#3787c0';
        } else if (precValue > 240 && precValue <= 260) {
            fillColor = '#2474b6';
        } else if (precValue > 260 && precValue <= 280) {
            fillColor = '#135fa7';
        } else if (precValue > 280 && precValue <= 300) {
            fillColor = '#084991';
        } else if (precValue > 300) {
            fillColor = '#08306b';
        }
    }



    return new Style({
        image: new Circle({
            radius: 7,
            fill: new Fill({
                color: fillColor
            }),
            stroke: new Stroke({
                color: '#232323',
                width: 0.5
            })
        })
    });






}


function riskStyleFunction(feature) {
    //console.log(feature);
    const value = feature.get('risk');
    var name = feature['id_'];
    let fillColor;
    let strokeColor;
    var breaks;

    //console.log(name);
    //console.log(value);

    if (name.includes('oueme')) {
        if (name.includes('risk_hq2')) {
            breaks = [3.37794, 3.50814];
        } else if (name.includes('risk_hq20')) {
            breaks = [3.32731, 3.58047];
        } else if (name.includes('risk_hq100')) {
            breaks = [3.42857, 3.68897];
        } else if (name.includes('risk_hq300')) {
            breaks = [3.36347, 3.75226];
        }
    } else if (name.includes('kumasi')) {
        breaks = [2.82576599999999978, 3.37726499999999996];
    }



    if (!value) {
        fillColor = '#FFFFFF00';
        strokeColor = '#FFFFFF00';
    } else if (value >= 0 && value < breaks[0]) {
        fillColor = '#FFFFFF';
        strokeColor = '#3b3b3b';
    } else if (value >= breaks[0] && value < breaks[1]) {
        fillColor = '#FF8080';
        strokeColor = '#3b3b3b';
    } else if (value >= breaks[1]) {
        fillColor = '#FF0000';
        strokeColor = '#3b3b3b';
    }

    let style = new Style({
        stroke: new Stroke({
            color: strokeColor,
            width: 2 // Adjust border thickness as needed
        }),
        fill: new Fill({
            color: fillColor
        })
    });

    //console.log(style);
    //console.log("END");

    return style;
}




// var VectorLayer = new Group({
//     title: "Vector Layer",
//     layers: [
//         new Vector({
//             title: "hq_100_risk_oueme",
//             info: "Smallest districts as smallest available administrative unit.",
//             visible: false,
//             source: new VectorSource({
//                 url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=furiflood%3Ahq_100_risk_oueme&maxFeatures=50&outputFormat=application%2Fjson",
//                 format: new GeoJSON(),
//             }),
//             style: DistricsStyle,
//         }),
//         new Vector({
//             title: "synop_stat",
//             info: "Synoptic stations.",
//             visible: false,
//             source: new VectorSource({
//                 url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=furiflood%3Asynop_stat_v2&maxFeatures=200&outputFormat=application%2Fjson",
//                 format: new GeoJSON(),
//             }),
//             style: PointStyle,
//         }),
//     ],
// });


var RasterLayer = new Group({
    title: "Indicators",
    layers: [],
});



const Overlays = new Group({
    title: "Boundaries",
    layers: [
        new Vector({
            title: "Districts",
            info: "Districts as smallest available administrative unit.",
            visible: false,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_2_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: DistricsStyle,
        }),
        new Vector({
            title: "Counties",
            info: "Counties inside the countries.",
            visible: false,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_1_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: CountiesStyle,
        }),
        new Vector({
            title: "Countries",
            info: "West African countries.",
            visible: true,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_0_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: CountriesStyle,
        }),
        new Vector({
            title: "Catchments",
            info: "Catchment areas in West Africa.",
            visible: false,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/furiflood/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=furiflood%3Acatchments_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: CatchmentStyle,
        }),
    ],
});



const Basemaps = new Group({
    title: "Base Maps",
    layers: [
        // ESRI Satellite Map
        new TileLayer({
            title: "ESRI Satellite",
            info: "ESRI Satellite Basemap, available at https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9.",
            baseLayer: true,
            visible: false,
            source: new XYZ({
                attributions: [
                    "Powered by Esri",
                    "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
                ],
                attributionsCollapsible: false,
                url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                maxZoom: 23,
                crossOrigin: "",
            }),
        }),
        // ESRI Basemap
        new TileLayer({
            title: "ESRI Basemap",
            info: "ESRI Topographic Basemap, available at https://www.arcgis.com/home/item.html?id=7dc6cea0b1764a1f9af2e679f642f0f5.",
            baseLayer: true,
            visible: false,
            source: new XYZ({
                attributions:
                    'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                    'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
                url:
                    "https://server.arcgisonline.com/ArcGIS/rest/services/" +
                    "World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            }),
            crossOrigin: "",
        }),
        // OSM
        new TileLayer({
            title: "OSM",
            info: "OpenStreetMap, available at https://www.openstreetmap.org/#map=6/12.030/2.249.",
            baseLayer: true,
            visible: true,
            source: new OSM(),
            crossOrigin: "",
        }),
    ],
});

var roi_oueme = new Vector({
    title: "Ouémé Basin",
    info: "Smallest districts as smallest available administrative unit.",
    visible: false,
    source: new VectorSource({
        url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_2_wa&outputFormat=application%2Fjson",
        format: new GeoJSON(),
    }),
})

var Cities = new ImageLayer({
    title: "Cities",
    visible: false,
    source: new ImageWMS({
        url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/wms",
        params: {
            VERSION: "1.1.1",
            STYLES: "",
            LAYERS: "landsurf:cities_v2",
            exceptions: "application/vnd.ogc.se_inimage",
            VENDORPARAMS: 'label:on'
        },
        ratio: 1,
        serverType: "geoserver",
        crossOrigin: "",
    }),
})


const crs = "EPSG:4326";

const view = new View({
    projection: crs,
    center: [-0.702519, 15.165076], //Coordinates of center
    zoom: 5.8, //zoom level of map
});

var map = new Map({
    controls: defaultControls({
        zoom: true,  // Explicitly enable zoom controls 
        className: 'ol-zoom-control'
    }),
    target: "map",
    layers: [
        Basemaps,
        RasterLayer,
        Overlays,
        Cities
    ],
    view: view,
    controls: [],
});





export { RasterLayer, map, view, riskStyleFunction, synopStyleFunction, Overlays, CountriesStyle, CountiesStyle, DistricsStyle, CatchmentStyle, SynopBlack, SynopGreen, SynopOrange, SynopBlue, SynopRed, roi_oueme, Cities };