import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/themes/translucent.css';
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import { Layers, getActiveLayer } from '../main';


function setSidebarHeight() {

  var map_height = $(window).height() - 100;
  //console.log(map_height);
  $(".sidebar-left").css("max-height", map_height);
}


function isConstrained() {
  return $("div.mid").width() == $(window).width();
}

function applyInitialUIState() {
  // if width of window is smaller than mid div, start collapsed
  if (isConstrained()) {
    $(".sidebar-left .sidebar-body").fadeOut('slide');
    $(".sidebar-right .sidebar-body").fadeOut('slide');
    $('.mini-submenu-left').fadeIn();
    $('.mini-submenu-right').fadeIn();
  }

}



$(function () {

  /////////////////////// INDICATOR MODAL TAB FUNCTIONALITY ////////////////////
  $(".tabs").on("click", function () {
    //console.log("tab click");
    var current_fs, next_fs;

    $(".tabs").removeClass("active");
    $(".tabs h5").removeClass("font-weight-bold");
    $(".tabs h5").addClass("text-muted");
    $(this).children("h5").removeClass("text-muted");
    $(this).children("h5").addClass("font-weight-bold");
    $(this).addClass("active");

    current_fs = $(".active");

    next_fs = $(this).attr('id');
    next_fs = "#" + next_fs + "1";

    $("fieldset").removeClass("show");
    $(next_fs).addClass("show");

    current_fs.animate({}, {
      step: function () {
        current_fs.css({
          'display': 'none',
          'position': 'relative'
        });
        next_fs.css({
          'display': 'block'
        });
      }
    });
  });

  /////////////////////// DOWNLOAD MODAL TAB FUNCTIONALITY ////////////////////
  $(".tabs").on("click", function () {

    var current_fs, next_fs;

    $(".tabs").removeClass("active");
    $(".tabs h5").removeClass("font-weight-bold");
    $(".tabs h5").addClass("text-muted");
    $(this).children("h5").removeClass("text-muted");
    $(this).children("h5").addClass("font-weight-bold");
    $(this).addClass("active");

    current_fs = $(".active");

    next_fs = $(this).attr('id');
    next_fs = "#" + next_fs + "1";

    $("fieldset").removeClass("show");
    $(next_fs).addClass("show");

    current_fs.animate({}, {
      step: function () {
        current_fs.css({
          'display': 'none',
          'position': 'relative'
        });
        next_fs.css({
          'display': 'block'
        });
      }
    });
  });

  // when clicking on submenu retract button for layer menu
  $('.sidebar-left .slide-submenu').on('click', function () {
    var thisEl = $(this);
    // fade out sidebar body
    // fade in mini-submenus
    thisEl.closest('.sidebar-body').fadeOut('slide', function () {
      $('.mini-submenu-left').fadeIn();
      //applyMargins();
    });
  });

  // when clickung on mini submenus for layers
  $('.mini-submenu-left').on('click', function () {
    var thisEl = $(this);
    // slide in sidebar body
    $('.sidebar-left .sidebar-body').toggle('slide');
    // hide mini-submenus
    thisEl.hide();
    //applyMargins();
  });

  $('.sidebar-right .slide-submenu').on('click', function () {
    var thisEl = $(this);
    thisEl.closest('.sidebar-body').fadeOut('slide', function () {
      $('.mini-submenu-right').fadeIn();
      //applyMargins();
    });
  });

  $('.mini-submenu-right').on('click', function () {
    var thisEl = $(this);
    $('.sidebar-right .sidebar-body').toggle('slide');
    thisEl.hide();
    //applyMargins();
  });


  // code to apply border radius to cards when they expanded
  // also apply shadow when expanded
  $(".card-title-link").on("click", function () {

    if ($(this).attr("class").includes("clicked")) {
      $(this).removeClass("clicked");
      $(this).addClass("inactive");
      $(this).parent().parent().attr('style', 'border-radius: 0.25rem !important');
      $(this).parent().parent().parent().css({ "box-shadow": "" });
    } else if ($(this).attr("class").includes("inactive")) {
      $(this).removeClass("inactive");
      $(this).addClass("clicked");
      $(this).parent().parent().attr('style', 'border-radius: 0.25rem 0.25rem 0 0 !important')
      $(this).parent().parent().parent().attr('style', 'box-shadow: 0 0px 10px #293956');
    }
  });

  // $(window).on("resize", applyMargins);

  applyInitialUIState();
  setSidebarHeight();
  // applyMargins();




  /////////////// Logic to show / hide input forms for indicator selection based on user selection



  // string to append to div when chirps data is available
  var html_chirps = '<select class="custom-select" id="typeRecentInput"><option value="chirps">chirps</option><option value="era5" selected>era5</option></select>'

  // string to append to div when chirps data is NOT available
  var html_nochirps = '<select class="custom-select" id="typeRecentInput"><option value="era5" selected>era5</option></select>'




  // make changes depending on selected indicator type (recent or projected) and data type (return values / synoptic)
  $("#dataTypeInput, #indicatorTypeInput").on("input", function () {
    var dataType = $('#dataTypeInput').val();
    var indicatorType = $('#indicatorTypeInput').val();

    if (dataType == 'synop') {
      if (indicatorType == 'recent') {
        //console.log("synop recent");
        $('#projectionTypeContainer, #projectionPeriodContainer, #scenarioContainer').slideUp();
        $('#baseLevelContainer, #returnPeriodContainer').slideDown();
      } else if (indicatorType == 'projection') {
        //console.log("synop projection");
        // $('#midtermSelect').show();
        // $('#longtermSelect').show();
        // $('#midtermSelect').removeAttr('selected');
        // $('#longtermSelect').attr('selected', 'selected');

        //console.log("rvs projection");
        $('#baseLevelContainer, #returnPeriodContainer, #projectionTypeContainer, #projectionPeriodContainer, #scenarioContainer').slideDown();
      }
    } else if (dataType == 'rvs') {
      if (indicatorType == 'recent') {
        //console.log("rvs recent");
        $('#projectionTypeContainer, #projectionPeriodContainer, #scenarioContainer').slideUp();
        $('#baseLevelContainer, #returnPeriodContainer').slideDown();
      } else if (indicatorType == 'projection') {
        // $('#midtermSelect').hide();
        // $('#longtermSelect').show();
        // $('#midtermSelect').removeAttr('selected');
        // $('#longtermSelect').attr('selected', 'selected');

        //console.log("rvs projection");
        $('#baseLevelContainer, #returnPeriodContainer, #projectionTypeContainer, #projectionPeriodContainer, #scenarioContainer').slideDown();
      }
    }


  })


  /////////
  // change catchment event frequency based on selected catchment
  $('#catchmentInput').on('input', function () {

    //console.log("CATCHMENT INPUT");

    var catchmendDataType = $('#catchmentDataInput').val();

    
    

    ///// OUEME
    if ($(this).val() == 'oueme') {

      $('#catchmentDurationSelectContainer').slideUp();

      //console.log($(this).val());
      $('.kumasi-event-5').removeAttr('selected');
      $('.oueme-event-2').attr('selected', 'selected');
      $('.oueme-event').show();
      $('.kumasi-event').hide();
      $('.kumasi-hazard-event-10').hide();

    ////// KUMASI
    } else if ($(this).val() == 'kumasi') {
      //console.log($(this).val());

      if (catchmendDataType == 'risk') {

        $('#catchmentDurationSelectContainer').slideUp();

        //console.log(catchmendDataType);
        $('.oueme-event-2').removeAttr('selected');
        $('.kumasi-event-5').attr('selected', 'selected');
        $('.kumasi-event').show();
        $('.oueme-event').hide();
        $('.kumasi-hazard-event-10').hide();

      } else if (catchmendDataType == 'hazard') {

        $('#catchmentDurationSelectContainer').slideDown();

        //console.log(catchmendDataType);
        $('.oueme-event-2').show();
        $('.oueme-event-2').attr('selected', 'selected');
        $('.kumasi-event-5').hide();
        $('.kumasi-event-50').hide();
        $('.oueme-event-20').hide();
        $('.oueme-event-100').hide();
        $('.oueme-event-300').hide();
        $('.kumasi-hazard-event-10').show();
        $('.kumasi-event-100').show();
      }

    }
  })

  $('#catchmentDataInput').on('input', function () {

    //console.log("CATCHMENT DATA INPUT");

    var catchmentType = $('#catchmentInput').val();


    ///// OUEME
    if (catchmentType == 'oueme') {

      $('#catchmentDurationSelectContainer').slideUp();

      //console.log($(this).val());
      $('.kumasi-event-5').removeAttr('selected');
      $('.oueme-event-2').attr('selected', 'selected');
      $('.oueme-event').show();
      $('.kumasi-event').hide();
      $('.kumasi-hazard-event-10').hide();

    ////// KUMASI
    } else if (catchmentType == 'kumasi') {
      //console.log($(this).val());

      if ($(this).val() == 'risk') {

        $('#catchmentDurationSelectContainer').slideUp();

        //console.log(catchmentType);
        $('.oueme-event-2').removeAttr('selected');
        $('.kumasi-event-5').attr('selected', 'selected');
        $('.kumasi-event').show();
        $('.oueme-event').hide();
        $('.kumasi-hazard-event-10').hide();

      } else if ($(this).val() == 'hazard') {

        $('#catchmentDurationSelectContainer').slideDown();

        //console.log(catchmentType);
        $('.kumasi-event-5').removeAttr('selected');
        $('.oueme-event-2').show();
        $('.oueme-event-2').attr('selected', 'selected');
        $('.kumasi-event-5').hide();
        $('.kumasi-event-50').hide();
        $('.kumasi-hazard-event-10').show();
        $('.kumasi-event-100').show();
      }

    }

    // if ($(this).val() == 'risk') {
    //   $('.catchment-oueme').show();
    //   $('.catchment-kumasi').show();
    //   $('.catchment-kumasi').removeAttr('selected');
    //   $('.catchment-oueme').attr('selected', 'selected');
    // } else if ($(this).val() == 'hazard') {
    //   $('.catchment-oueme').show();
    //   $('.catchment-kumasi').hide();
    //   $('.catchment-kumasi').removeAttr('selected');
    //   $('.catchment-oueme').attr('selected', 'selected');
    // }
  })





  $("#indicatorTypeInput").on("input", function () {
    var indicatorType = $(this).val();
    var dataType = $('#dataTypeInput').val();



    // switch (indicatorType) {
    //   // if recent, show forms for recent data
    //   case "recent":
    //     //console.log("Type is RECENT");
    //     $('.recentProjectionContainer').slideDown();
    //     $('.projectionContainer').slideUp();
    //     break;
    //   // if projection, show other forms
    //   case "projection":
    //     //console.log("Type is PROJECTION");
    //     $('.projectionContainer').slideDown();
    //     $('.recentProjectionContainer').slideDown();
    //     break;
    // }
    if (dataType == 'synop') {
      if (indicatorType == 'recent') {
        $('.rvsContainer').slideUp();
        $('.projectionContainer').slideUp();
        $('.recentProjectionContainer').slideDown();
      } else if (indicatorType == 'projection') {
        $('.rvsContainer').slideUp();
        $('.projectionContainer').slideDown();
        $('.recentProjectionContainer').slideDown();
      }
    } else if (dataType == 'rvs') {
      if (indicatorType == 'recent') {
        $('.rvsContainer').slideDown();
        $('.projectionContainer').slideUp();
        $('.recentProjectionContainer').slideDown();
      } else if (indicatorType == 'projection') {
        $('.rvsContainer').slideDown();
        $('.projectionContainer').slideDown();
        $('.recentProjectionContainer').slideDown();
      }
    }
  })



  // change tooltip for event frequency
  // dependent on the selected catchment area
  $("#catchmentInput").on("input", function () {
    let val = $(this).val();
    
    switch (val) {
      case 'oueme':
        $('#eventFreqKumasiTooltip').remove();
        $('#eventFreqHeader').append('<i id="eventFreqOuemeTooltip"class="fa-regular fa-circle-question event-freq-tooltip"></i>');
        tippy('#eventFreqOuemeTooltip', {
          content: 'Derived from Bonou flood statistics.',
          theme: 'blue-dark',
        });
        break;
      case 'kumasi':
        $('#eventFreqOuemeTooltip').remove();
        $('#eventFreqHeader').append('<i id="eventFreqKumasiTooltip"class="fa-regular fa-circle-question event-freq-tooltip"></i>');
        tippy('#eventFreqKumasiTooltip', {
          content: 'Derived from precipitation statistics.',
          theme: 'blue-dark',
        });
        break;
    }
  })


  // when indicator is selected, test if chirps data is available and change 
  // type formular accordingly
  // $("#modalIndicatorSaveButton").on("click", function () {
  //   var indicator = $("#indicatorSelectInput").val();
  //   //console.log(indicator);
  //   //console.log(ind_chirps[indicator]);

  //   if (ind_chirps[indicator]) {
  //     $("#typeRecentDiv").html(html_chirps);
  //   } else {
  //     $("#typeRecentDiv").html(html_nochirps);
  //   }

  // })



  /////////// image larger onclick
  $(".chart").on("click", function () {
    //console.log("image clicked");
  })




  /////////////////////////////////// Tippy Tooltips ////////////////////////////

  // left sidebar
  tippy('#basemapTooltip', {
    content: '"Flood Hazard" data will be better visible when selecting the "ESRI Satellite" Basemap in the "Layers" tab.',
    theme: 'blue-dark',
  });

  tippy('#typeTooltip', {
    content: 'Select from either historic or projected datasets.',
    theme: 'blue-dark',
  });

  tippy('#legendTooltip', {
    content: 'Only the legend of the uppermost visible raster layer from the "Layers" card is displayed.',
    theme: 'blue-dark',
  });

  // right sidebar
  tippy('#popupTooltip', {
    content: 'Investigate and compare visualized indicators on the map by clicking the button below.',
    theme: 'blue-dark',
  });

  tippy('#selectRegionTooltip', {
    content: 'Select a region from the available administrative boundaries for further analysis.',
    theme: 'blue-dark',
  });

  tippy('#chartTooltip', {
    content: 'Create a chart from the selected indicator and region.',
    theme: 'blue-dark',
  });

  tippy('#eventFreqOuemeTooltip', {
    content: 'Derived from Bonou flood statistics.',
    theme: 'blue-dark',
  });

  tippy('#eventFreqKumasiTooltip', {
    content: 'Derived from precipitation statistics.',
    theme: 'blue-dark',
  });




  ///////////////////// feedback ///////////////////////
  $("#feedback").on("click", function () {
    $("#feedbackModal").modal('toggle');

  })




  // when footer is expanding / collapsing
  // change chevron direction on right side
  $('#collapseOne').on('show.bs.collapse hide.bs.collapse', function (e) {
    if (e.type == 'show') {
      $('#footerIconRight').addClass('fa-chevron-down');
      $('#footerIconRight').removeClass('fa-chevron-up');

    } else {
      $('#footerIconRight').addClass('fa-chevron-up');
      $('#footerIconRight').removeClass('fa-chevron-down');

    }
  })






  ///////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// ADJUST GRAPH MAKING MODALS ///////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  // the modals for making graphs needs to be adjusted based on the selected dataset
  // e.g. when selecting a recent layer, no scenario should be choosable -> will be grayed out


  $("#loadLayerButton").on("click", function () {
    let activeLayer = getActiveLayer(Layers);

    //console.log("HALLOHALLO?")
    //console.log(activeLayer);
    //console.log(activeLayer.class)

    switch (activeLayer.class) {
      ///////// CLIMATE LAYER ////////////
      case 'climate':
        //console.log("CLIMATE");
        break;
      ///////// DROUGHT LAYER ////////////
      case 'drought':
        //console.log("DROUGHT");
        if (activeLayer.type == 'recent') {
          //console.log('recent');
          $('#droughtChartScenarioSelect').css('opacity', '0.5');
          $('#droughtChartScenarioSelect').css('pointer-events', 'none');
        } else if (activeLayer.type == 'projection') {
          //console.log('projection');
          $('#droughtChartScenarioSelect').css('opacity', '1');
          $('#droughtChartScenarioSelect').css('pointer-events', 'auto');
        }
      ///////// SEASON LAYER ////////////
      case 'season':
        //console.log("SEASON");
        if (activeLayer.type == 'recent') {
          //console.log('recent');
          $('#seasonChartScenarioSelect').css('opacity', '0.5');
          $('#seasonChartScenarioSelect').css('pointer-events', 'none');
        } else if (activeLayer.type == 'projection') {
          //console.log('projection');
          $('#seasonChartScenarioSelect').css('opacity', '1');
          $('#seasonChartScenarioSelect').css('pointer-events', 'auto');
        }
        break;
      ///////// CROP LAYER ////////////
      case 'crop':
        //console.log("CROP");
        if (activeLayer.type == 'recent') {
          //console.log('recent');
          $('#stageChartScenarioSelect').css('opacity', '0.5');
          $('#stageChartScenarioSelect').css('pointer-events', 'none');
          $('#cropChartScenarioSelect').css('opacity', '0.5');
          $('#cropChartScenarioSelect').css('pointer-events', 'none');
        } else if (activeLayer.type == 'projection') {
          //console.log('projection');
          $('#stageChartScenarioSelect').css('opacity', '1');
          $('#stageChartScenarioSelect').css('pointer-events', 'auto');
          $('#cropChartScenarioSelect').css('opacity', '1');
          $('#cropChartScenarioSelect').css('pointer-events', 'auto');
        }
        break;
    }


    // opacity: 0.5;
    // pointer-events: none;

  });


});